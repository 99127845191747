import {Button, IconButton} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import "./Account.css";
import SettingsIcon from "@material-ui/icons/Settings";
import * as api from "../../app/api";
import {useHistory} from "react-router-dom";
import {Divider, Grid} from "@mui/material";
import {Lightbulb} from "@mui/icons-material";
import {CreditCard, SimCard, YouTube} from "@material-ui/icons";
import PersonIcon from "@material-ui/icons/Person";
import * as libphonenumber from "libphonenumber-js";
import {sessionInfo} from "../../app/api";
import AddIcon from "@material-ui/icons/Add";
import {showLongToast} from "../../app/utils";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

function Account({user, session, setShowAddNumber}) {
    const history = useHistory();
    const [subscription, setSubscription] = useState(null)

    const hasAndroidDevice = () => {
        if (!session || !session.devices) return false;
        return session.devices.find(device => device.type === "android") != null
    }

    useEffect(() => {
        function validateSubscription(s) {
            if (!s) {
                setSubscription(null);
                return;
            }
            if (s.subscriber) {
                let entitlements = s.subscriber.entitlements;
                let existing;
                if (entitlements && entitlements.subscription_lite) {
                    existing = entitlements.subscription_lite;
                }
                if (entitlements && entitlements.subscription) {
                    existing = entitlements.subscription;
                }
                setSubscription({
                    expires_date: existing?.expires_date,
                    product_identifier: existing?.product_identifier,
                    purchase_date: existing?.purchase_date,
                })
            } else {
                setSubscription(null)
            }
        }

        const checkSubscription = () => {
            api.getSubscription((response) => {
                validateSubscription(response.subscription);
            });
        }
        checkSubscription();
    }, []);

    /**
     * check is user has a valid subscription
     * @returns true if user has a subscription that hasn't expired
     */
    function hasSubscription() {
        if (subscription) {
            console.log("subscription type " + subscription.product_identifier);
            console.log("subscription date " + subscription.purchase_date);
            console.log("subscription expires " + subscription.expires_date);

            let now = new Date();
            let purchased = new Date(subscription.purchase_date);
            let expires = new Date(subscription.expires_date);
            return purchased && purchased < now && expires > now;
        }
        return false;
    }

    function CANumbers({numbers}) {
        const addNumber = () => {
            setShowAddNumber(true)
        }
        return (<div>{numbers?.map(number => {
                return (<CANumber key={number.number} number={number}/>);
            })}
                <br/>
                {(<Button onClick={addNumber}><AddIcon/>&nbsp;Add Number</Button>)}
            </div>
        );

    }

    function CANumber({number}) {
        const [showTest, setShowTest] = useState(false);
        const doTest = () => {
            api.testNumber(number.number)
                .then((data) => {
                    if (data.status === "OK") {
                        showLongToast('Test call started, please hangup the incoming call');
                    } else {
                        showLongToast(data.error);
                    }
                })
        }
        const title = () => {
            let t;
            try {
                let n = libphonenumber.parse(number.number);
                let formatted = libphonenumber.format(n, n.country === sessionInfo?.country ? 'NATIONAL' : 'INTERNATIONAL');
                if (formatted) {
                    t = formatted;
                }
            } catch (e) {
                t = number.number;
                console.log(e)
            }
            return t || "Unknown"
        }
        return (<div className="account-number-item"><br/>
            {title()}&nbsp;
            {!number.voip && <Button onClick={() => setShowTest(true)}>Test</Button>}
            {showTest &&  <Dialog
                open={showTest}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Test Number"} {title()}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to test this number? We will make a test call to check
                        if call forwarding is working correctly. Please hangup the call!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowTest(false)}>Cancel</Button>
                    <Button onClick={doTest}>
                        Test number
                    </Button>
                </DialogActions>
            </Dialog>}
        </div>)

    }

    return (
        <div className="account">
            <div className="page-settings">
                <div className="page-settingsLeft">
                </div>
                <div className="page-settingsRight">
                    <IconButton onClick={() => history.push("/settings")}>
                        <SettingsIcon/>
                    </IconButton>
                </div>
            </div>
            {/*<div className="callList-sections">*/}
            {/*    <Section Icon={InboxIcon} title="Primary" color="red" selected/>*/}
            {/*    <Section Icon={PeopleIcon} title="Social" color="#1A73E8"/>*/}
            {/*    <Section Icon={LocalOfferIcon} title="Promotions" color="green"/>*/}
            {/*</div>*/}


            <h2 className="app-title">Account</h2>
            <br/>
            <div className="callList-list">
                <Grid container spacing={10}>
                    <Grid item xs={10}>
                        <div className="account-items">
                            <div className="account-details">
                                <span className="account-item-title">User</span>
                                <IconButton>
                                    <PersonIcon/>
                                </IconButton>
                                <br/>
                                <br/>
                                <b>Name</b><br/>
                                <span className="account-item">{user?.displayName}</span>
                                <br/>
                                <br/>
                                <b>Email</b><br/>
                                <span className="account-item">{user?.email}</span>
                                <br/>
                                <br/>
                                <b>Country</b><br/>
                                <span className="account-item">{session?.country}</span>
                            </div>
                            <div className="account-details">
                                {hasSubscription() && <div>
                                    <span className="account-item-title">Subscription</span>
                                    <IconButton onClick={() => history.push("/subscription")}>
                                        <CreditCard/>
                                    </IconButton>
                                    <br/>
                                    <br/>
                                    <b>Product</b><br/>
                                    <span className="account-item">{subscription.product_identifier}</span>
                                    <br/>
                                    <br/>
                                    <b>Started</b><br/>
                                    <span
                                        className="account-item">{new Date(subscription.purchase_date).toLocaleString()}</span>
                                    <br/>
                                    <br/>
                                    <b>Expires</b><br/>
                                    <span
                                        className="account-item">{new Date(subscription.expires_date).toLocaleString()}</span>
                                </div>
                                }
                                {
                                    hasSubscription() === false && <div>
                                        <span className="account-item-title">Subscription</span>
                                        <IconButton>
                                            <CreditCard/>
                                        </IconButton>

                                        <br/>
                                        <br/>
                                        <span>Your account doesn't have a subscription</span>
                                        <br/>
                                        <br/>
                                        <Button onClick={() => history.push("/subscription")}>Buy Subscription</Button><br/>
                                    </div>
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                        <Divider/>
                        <div className="account-tips">
                            <span className="account-item-title">Numbers</span>
                            <IconButton>
                                <SimCard/>
                            </IconButton>
                            <br/>
                            <CANumbers numbers={session?.verified_numbers}></CANumbers>
                        </div>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                        <Divider/>
                        <div className="account-tips">
                            <span className="account-item-title">Tips</span>
                            <IconButton>
                                <Lightbulb/>
                            </IconButton>
                            <br/>
                            {hasAndroidDevice() && <div>
                                <br/>
                                To answer your incoming calls from your Android smartphone on this browser enable
                                the <b>Remote Call</b> option in the settings screen
                                <br/>
                                <br/>
                                <Divider/>
                            </div>
                            }
                            <br/>
                            You can also install Call Assistant on your <b>iPad</b> or <b>Android tablet</b> and
                            you'll be able receive incoming calls on all your devices.
                            <br/>
                            <br/>
                            <Divider/>
                            <br/>
                            <span className="account-item-title">YouTube</span>
                            <IconButton>
                                <YouTube/>
                            </IconButton>
                            <br/>
                            <br/>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/GNEHBcH609o"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>);
}

export default Account;
