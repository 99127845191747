import {createSlice} from "@reduxjs/toolkit";

export const callSlice = createSlice({
    name: "call",
    stream: null,
    initialState: {
        device: null,
        selectedCall: null,
        calls: [],
        selectedCalls: [],
    },
    reducers: {
        updateDevice: (state, action) => {
            state.device = action.payload;
        },
        updateStream: (state, action) => {
            state.stream = action.payload;
        },
        selectCall: (state, action) => {
            state.selectedCall = action.payload;
        },
        removeCall: (state, action) => {
            if (state.selectedCall && state.calls) {
                const newCalls = state.calls.filter(call => call.id !== state.selectedCall.id)
                if (newCalls.length !== state.calls.length) {
                    state.calls = newCalls;
                }
            }
        },
        removeSelectedCall: (state, action) => {
            console.log('removing call', action.payload);
            const newSelectedCalls = state.selectedCalls.filter(call => {
                return call !== action.payload.id
            })
            state.selectedCalls = newSelectedCalls;
        },
        addSelectedCall: (state, action) => {
            console.log('adding call', action.payload);
            const exists = state.selectedCalls.filter(call => call === action.payload.id)
            if (exists && exists.length > 0) {
                return;
            }
            const newSelectedCalls = [...state.selectedCalls, action.payload.id];
            state.selectedCalls = newSelectedCalls;
        },
        setCalls: (state, action) => {
            state.calls = action.payload;
        },
        setSelectedCalls: (state, action) => {
            state.selectedCalls = action.payload;
        },
    },
});

export const {selectCall,
    setCalls,
    removeCall,
    removeSelectedCall,
    addSelectedCall,
    setSelectedCalls,
    updateStream,
    updateDevice} = callSlice.actions;
export const selectOpenCall = (state) => state.call.selectedCall;
export const getCalls = (state) => state.call.calls;
export const getSelectedCalls = (state) => state.call.selectedCalls;
export const getDevice = (state) => state.call.device;
export const getStream = (state) => state.call.stream;
export default callSlice.reducer;