import {CircleMenu, CircleMenuItem, TooltipPlacement,} from "react-circular-menu";

import {useDrag, useDrop} from 'react-dnd'
import React, {useEffect, useRef, useState} from "react";
import "./Settings.css";
import "./Buttons.css";
import * as api from "../../app/api";
import {Button, IconButton} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {Add, PlayArrow, Refresh, SaveAlt, Stop} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import {useHistory} from "react-router-dom";
import Loading from "../../Loading";
import {getButtons, setButton, setButtons, setIcons} from "../../features/buttonSlice";
import {useDispatch, useSelector} from "react-redux";

function Buttons({pendingChanges, setPendingChanges, session}) {

    const dispatch = useDispatch();
    const history = useHistory();
    const audioRef = useRef();
    const buttons = useSelector(getButtons)
    const [pendingButton, setPendingButton] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initButtons();
        loadIcons();
    }, []);

    const initButtons = () => {
        setLoading(true)
        api.loadButtons().then(response =>  {
            setLoading(false)
            console.log('call buttons ', response);
            dispatch(setButtons(response));
        })
    }

    const loadIcons = () => {
        const xhr = new XMLHttpRequest();
        let icons = []
        xhr.onload = function () {
            let iconsXml = xhr.responseXML.documentElement
            for (let i in iconsXml.children) {
                let categories = iconsXml.children[i];
                for (let j in categories.children) {
                    let icon = categories.children[j];
                    if (!icon.attributes) {
                        continue;
                    }
                    let id = icon.attributes["id"].value;
                    if (!id) continue
                    let path = icon.attributes["path"].value;
                    let tags = icon.attributes["tags"].value;
                    icons[id] = {
                        id: id,
                        path: path,
                        tags: tags
                    };
                }
            }
            setIcons(icons);
        }

        xhr.onerror = function (error) {
            console.error("error loading icons", error);
        }

        xhr.open("GET", "/images/icons.xml");
        xhr.responseType = "document";
        xhr.send();
    }

    const menuButtonClicked = (button) => {
        changeToButton(button);
    }

    const changeToButton = (button) => {
        dispatch(setButton(button));
        history.push("/button")
    }

    const updateButtons = () => {
        //setSelectedButton(null);
        console.log('saving buttons', buttons)
        setLoading(true)
        api.saveButtons(buttons).then(() => {
            setLoading(false)
            setButtons(null);
            setButton(null);
            setPendingChanges(false);
            initButtons();
        })
    }

    const addButton = () => {
        let newButton = {
            color: "#ab1a1a",
            mode: 0,
            language: 'en-US',
            voice: 'polly|Joanna',
            iconId: 957,
            title: 'New Button',
            text: 'Hi, ' + session.name + ' is not available, do you want to leave a message?',
            pos: buttons?.length || 0
        }
        const newButtons = [...buttons, newButton];
        dispatch(setButtons(newButtons))
        changeToButton(newButton);
    }

    function ButtonsPendingChangesDialog() {

        const cancelButton = () => {
            setPendingButton(null)
        };

        const continueToButton = () => {
            if (pendingButton) {
                setPendingButton(null);
                setPendingChanges(false);
                changeToButton(pendingButton);
            }
        };

        return (
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Pending Changes"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        There are pending changes that were not saved. Do you want to continue without saving?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={continueToButton}>Continue</Button>
                    <Button onClick={cancelButton}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    const ButtonRow = ({button, onClick}) => {
        const [playing, setPlaying] = useState(false)
        let audioRef = useRef();
        const ref = useRef()
        let dispatch = useDispatch();

        function swapElements(i1, i2) {
            let copy = [...buttons];
            console.log('buttons before swap', copy);
            // Step 1
            let temp1 =  {... copy[i1], pos: i2};
            // Step 2
            copy[i1] = {...copy[i2], pos: i1};

            // Step 3
            copy[i2] = temp1;

            setPendingChanges(true)

            console.log('buttons after swap', copy)
            dispatch(setButtons(copy))
        }

        const [collectedProps, drop] = useDrop(() => ({
            accept: "button",
            drop: (item, monitor) => {
                let newPos = button.pos;
                let pos = item.button.pos;
                console.log('moved button ' + pos + " to " + newPos);
                swapElements(pos, newPos)
                item.button = {...item.button, pos: pos}
                button = {...button, pos: newPos}
            }
        }))
        const [{isDragging}, drag, dragPreview] = useDrag(() => ({
            type: "button",
            item: {button},
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            })
        }))
        function playAction(e) {
            let url = getRecordingUrl()
            if (audioRef.current.paused !== false) {
                console.log( "playing " + url);
                audioRef.current.setAttribute("src", getRecordingUrl());
                audioRef.current.play();
            } else {
                console.log( "pausing " + url);
                audioRef.current.pause();
            }
            e.stopPropagation()
        }

        function getRecordingUrl() {
            return api.server + "/api/tts?text=" + encodeURIComponent(button.text)
                + "&voice_id=" + encodeURIComponent( button.voice)
                + "&lang=" + encodeURIComponent( button.language)
        }

        function onGreetingPlaying() {
            setPlaying(true)
        }
        function onGreetingPaused() {
            setPlaying(false)
        }

        function deleteButton(e) {
            let temp = buttons.filter(b => button.pos !== b.pos)
            dispatch(setButtons(temp))
            e.stopPropagation()
        }

        const opacity = isDragging ? 0 : 1
        drag(drop(ref))

        return (<div ref={ref} style={{ opacity }} className="itemRow fadeIn" onClick={onClick}>
                <div className="itemRow-options">
                    <IconButton>
                        <svg style={{fill: button.color ? ("#" + button.color.slice(-6)) : "red"}} className="list-assistant-icon" >
                            <image href={"/images/icons/svg/" + button.iconId + ".svg"} className="list-assistant-icon" />
                        </svg>
                    </IconButton>
                </div>
                <div className="greetingRow-title">
                    <b>{button.title}</b>
                    <br/>
                    <span className="greeting-item-text">{ button.text}</span>
                </div>
                <div>
                    <Button className="greetingRow-btn" onClick={deleteButton}>
                        <DeleteIcon/><br/>Delete
                    </Button>

                    {(!playing) &&  <Button className="greetingRow-btn"><PlayArrow onClick={playAction}/><br/>Play</Button>}
                    {playing &&  <Button className="greetingRow-btn"><Stop onClick={playAction}/><br/>Stop</Button>}

                </div>
                <audio ref={audioRef} onPlaying={onGreetingPlaying} onPause={onGreetingPaused} ></audio>
            </div>
        );
    }


    return (<div className="fadeIn">
        <div className="page-settings">
            <div className="page-settingsLeft">
                { buttons && <Button onClick={addButton}><Add/>&nbsp;Add Button</Button>}
                { buttons && <Button onClick={updateButtons}><SaveAlt/>&nbsp;Update Buttons</Button>}
                <Button onClick={initButtons}><Refresh/>&nbsp;Refresh</Button>
            </div>
            <div className="page-settingsRight">
                <IconButton onClick={() => history.push("/settings")}>
                    <SettingsIcon/>
                </IconButton>
            </div>
        </div>
        <h2 className="app-title">Assistant</h2>
        <div className="buttons-container">
        <div className="buttons-list">
        {
            buttons?.map((button) => (
                <ButtonRow
                    key={button.pos}
                    button={button}
                    onClick={() => changeToButton(button)}
                />
            ))}
            <br/>
            <br/>
        </div>
        {buttons && <div className="buttons-preview">
                <ButtonsMenu buttons={buttons} clickHandler={menuButtonClicked} />
            </div>
            }
        </div>
        {loading && <div><Loading/></div>}
        <audio ref={audioRef}></audio>
        {pendingButton && <ButtonsPendingChangesDialog/>}
    </div>);
}

export const ButtonsMenu = ({buttons, clickHandler}) => {
    function menuLoaded(event) {
        event.target.click();
    }
    return (<CircleMenu
        className="setting-buttons-menu"
        startAngle={-15}
        rotationAngle={360}
        itemSize={2}
        menuToggleElement={<img onLoad={menuLoaded} alt="Buttons Menu" className="buttons-menu-img"
                                src="/images/call_assistant.png"/>}
        radius={Math.max(4, buttons?.length)}
        rotationAngleInclusive={false}
    >
        {buttons?.map(button => (
            <CircleMenuItem
                key={button?.title || "New"}
                onClick={() => clickHandler(button)}
                tooltip={button?.title}
                style={{border: 0, background: button?.color ? ("#" + button?.color.slice(-6)) : "red"}}
                tooltipPlacement={TooltipPlacement.Bottom}
            >
                <img className="menu-button-icon" alt={button?.text} src={"/images/icons/svg/" + button.iconId + ".svg"}/>
            </CircleMenuItem>
        ))}
    </CircleMenu>)
}

export default Buttons;
