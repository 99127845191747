import React, {useState} from "react";
import "./Header.css";
import {Avatar, Card, ClickAwayListener, IconButton, List, ListItem} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AppsIcon from "@material-ui/icons/Apps";
import NotificationsIcon from "@material-ui/icons/Notifications";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../features/userSlice";
import {auth} from "../../firebase.js";
import * as api from "../../app/api"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {selectCall} from "../../features/callSlice";
import {selectVoicemail} from "../../features/voicemailSlice";
import {useHistory} from "react-router-dom";
import {Link} from "@mui/material";
import Loading from "../../Loading";

function Header() {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [showLogout, setShowLogout] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const signOut = () => {
        setShowLogout(false);
        api.logout(function (data) {
            console.log('logout result', data)
            auth.signOut().then(() => {
                console.log('Firebase logout done')
                window.location.reload();
            });
        })
    };
    const showLogoutDialog = () => {
        setShowLogout(true);
    };

    const cancel = () => {
        setShowLogout(false);
    };

    let searchTimeout = null;

    const handleSearchTextChanged = (event) => {
        const term = event.target.value;

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if (term && term.length > 1) {
            searchTimeout = setTimeout(function () {
                doSearch(term);
            }, 500)
        } else {
            setSearchResult(null);
        }
    }
    const doSearch = (query) => {
        setLoading(true);
        setSearchResult(null);
        api.searchCalls(query, 50, function (results) {
            setLoading(false);
            results.query = query;
            console.log('search results', results);
            setSearchResult(results);
        });
    }

    const Highlighted = ({text = "", highlight = ""}) => {
        if (!highlight.trim()) {
            return <span>{text}</span>;
        }
        const regex = new RegExp(`(${highlight.trim().replace(' ', '|')})`, "gi");
        const parts = text.split(regex);

        return (
            <span>
      {parts.filter(String).map((part, i) => {
          return regex.test(part) ? (
              <mark key={i}>{part}</mark>
          ) : (
              <span key={i}>{part}</span>
          );
      })}
    </span>
        );
    };

    const openCall = (call) => {
        dispatch(
            selectCall({
                id: call.id,
                call_id:call.call_id,
                number: call.number,
                date: call.date,
                duration: call.duration,
                type: call.type,
                transcription: call.transcription,
                video: call.video,
                messages: call.messages,
                recording: call.recording
            })
        );
        history.push("/call");
    };

    const openVoicemail = (voicemail) => {
        dispatch(
            selectVoicemail({
                id: voicemail.id,
                sid: voicemail.sid,
                number: voicemail.from,
                date: voicemail.created,
                duration: voicemail.duration,
                transcription: voicemail.text,
                recording: voicemail.url,
                read: voicemail.read
            }));
        history.push("/voicemail");
    };

    const callSearchResultClicked = (message) => {
        api.getCallBySid(message.call_sid, (result) => {
            console.log('call retrieved ', result);
            if (result) {
                setSearchResult(null);
                openCall(result);
            }
        });
    }

    const voicemailSearchResultClicked = (voicemail) => {
        setSearchResult(null);
        openVoicemail(voicemail);
    }

    function SearchResult(props) {
        return (<ClickAwayListener onClickAway={() => setSearchResult(null)}><Card className="search-results">
            <span className="search-title">Search results "{props.results?.query}"</span>
            <br/>
            <List>
                {props.results?.messages?.length === 0 && <span className="search-title">No calls found</span>}
                {props.results?.messages?.length > 0 && <span className="search-title">Calls ({props.results.messages.length || "0"})</span>}
                {props.results?.messages?.map((message) => (<ListItem key={message.date} >
                    <div className="search-result-item" onClick={() => callSearchResultClicked(message)}>
                        {message.from && <span>{message.from}&nbsp;</span>}
                        <Highlighted
                            text={message.message}
                            highlight={props.results?.query}/>
                        <br/><div className="search-result-item-date">{new Date(message.date).toLocaleString()}</div>
                    </div>
                </ListItem>))}
                <br/>
                <hr/>
                <br/>
                {props.results?.voicemails?.length === 0 && <span className="search-title">No voicemails found</span>}
                {props.results?.voicemails?.length > 0 && <span className="search-title">Voicemails ({props.results.voicemails.length || "0"})</span>}
                {props.results?.voicemails?.map((voicemail) => (<ListItem key={voicemail.id} >
                    <div className="search-result-item" onClick={() => voicemailSearchResultClicked(voicemail)}>
                        <Highlighted
                            text={voicemail.text}
                            highlight={props.results?.query}/>
                        <br/><div className="search-result-item-date">{new Date(voicemail.date).toLocaleString()}</div>
                    </div>
                </ListItem>))}
            </List>
        </Card></ClickAwayListener>)
    }

    return (
        <div className="header">
            <div className="header-left">
            </div>
            <div className="header-middle">
                <SearchIcon/>
                <input type="text" placeholder="Search calls" onChange={handleSearchTextChanged}/>
                <ArrowDropDownIcon className="header-inputCaret"/>
                {searchResult && <SearchResult results={searchResult}/>}
            </div>
            <div className="header-right">
                <Link target="_blank" rel="noopener" href="https://callassistant.ai/faq">
                    <IconButton>
                        <HelpOutlineIcon/>
                    </IconButton>
                </Link>
                <IconButton>
                    <NotificationsIcon/>
                </IconButton>
                <IconButton>
                    <AppsIcon/>
                </IconButton>
                <Avatar className="header-user-img" onClick={showLogoutDialog} src={user?.photoUrl}/>
            </div>

            {loading && <Loading />}

            {showLogout && <Dialog
                open={showLogout}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Logout"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancel}>Cancel</Button>
                    <Button onClick={signOut}>
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>}
        </div>
    );
}

export default Header;
