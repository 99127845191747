import {Button, IconButton} from "@material-ui/core";
import React from "react";
import "./NumberList.css";
import SettingsIcon from "@material-ui/icons/Settings";
import NumberRow from "../NumberRow/NumberRow";
import AddIcon from "@material-ui/icons/Add";
import {useHistory} from "react-router-dom";

function NumberList({session}) {
    const history = useHistory();
    return (
        <div className="numberList">
            <div className="page-settings">
                <div className="page-settingsLeft">
                </div>
                <div className="page-settingsRight">
                    <IconButton onClick={() => history.push("/settings")}>
                        <SettingsIcon/>
                    </IconButton>
                </div>
            </div>
            {/*<div className="callList-sections">*/}
            {/*    <Section Icon={InboxIcon} title="Primary" color="red" selected/>*/}
            {/*    <Section Icon={PeopleIcon} title="Social" color="#1A73E8"/>*/}
            {/*    <Section Icon={LocalOfferIcon} title="Promotions" color="green"/>*/}
            {/*</div>*/}


            <h2 className="app-title">Numbers</h2>
            <div className="numberList-list">
                {(session.verified_numbers == null || session.verified_numbers.length === 0) && <span className="numberList-no-numbers">No Numbers</span>}
                {
                    session.verified_numbers.map((number) => (
                        <NumberRow
                            key={Math.random()}
                            number={number.number}
                            date={number.date}
                            callerId={number.caller_id}
                            voip={number.voip}
                        />
                    ))}
            </div>
            <Button
                className="add-number"
                startIcon={<AddIcon fontSize="large"/>}
            >
                Add Number
            </Button>


        </div>
    );
}

export default NumberList;
