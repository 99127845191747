import Button from "@mui/material/Button";
import React, {useState} from "react";
import "./ContactList.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SettingsIcon from "@material-ui/icons/Settings";
import ContactRow from "../ContactRow/ContactRow";
import {useSelector} from "react-redux";
import {getContacts} from "../../features/contactSlice";

function ContactList({pageSize}) {

    const contacts = useSelector(getContacts)
    const [currentContactsPage, setCurrentContactsPage] = useState(0);

    const nextPage = () => {
        console.log('next page ' + currentContactsPage + 1)
        setCurrentContactsPage(currentContactsPage + 1);
    }
    const previousPage = () => {
        setCurrentContactsPage(Math.max(0, currentContactsPage - 1));
    }

    return (
        <div className="contactList">
            <div className="page-settings">
                <div className="page-settingsLeft">
                </div>
                <div className="page-settingsRight">
                    <Button onClick={() => previousPage() } disabled={currentContactsPage === 0}>
                        <ChevronLeftIcon/> Previous Page
                    </Button>
                    <Button onClick={() => nextPage()} disabled={currentContactsPage === Math.floor(contacts.length / pageSize) }>
                        Next Page <ChevronRightIcon />
                    </Button>
                    <Button>
                        <SettingsIcon/> Settings
                    </Button>
                </div>
            </div>

            <h2 className="app-title">Contacts</h2>

            <div className="contactList-list">
                {(contacts == null || contacts.length === 0) && <span className="contactList-no-contacts">No Contacts</span>}
                {
                    contacts.slice(currentContactsPage * pageSize, currentContactsPage * pageSize + pageSize).map((contact) => (
                        <ContactRow
                            key={Math.random()}
                            id={contact.id}
                            name={contact.name}
                            numbers={contact.numbers}
                        />
                    ))}
            </div>
        </div>
    );
}

export default ContactList;
