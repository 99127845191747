import {IconButton} from "@material-ui/core";
import React from "react";
import "./DeviceList.css";
import SettingsIcon from "@material-ui/icons/Settings";
import DeviceRow from "../DeviceRow/DeviceRow";
import {useHistory} from "react-router-dom";

function DeviceList({session}) {
    const history = useHistory();
    return (
        <div className="deviceList">
            <div className="page-settings">
                <div className="page-settingsLeft">
                </div>
                <div className="page-settingsRight">
                    <IconButton onClick={() => history.push("/settings")}>
                        <SettingsIcon/>
                    </IconButton>
                </div>
            </div>

            <h2 className="app-title">Devices</h2>
            <div className="deviceList-list">
                {(session.devices == null || session.devices.length === 0) && <span className="callList-no-calls">No Devices</span>}
                {
                    session.devices.map((device) => (
                        <DeviceRow
                            key={device.id}
                            id={device.id}
                            type={device.type}
                            ip={device.ip}
                            brand={device.brand}
                            device={device.device}
                            manufacturer={device.manufacturer}
                            model={device.model}
                            name={device.name}
                            operator={device.operator}
                            versionId={device.version_id}
                            version={device.version}
                            lastActivity={device.last_activity}
                        />
                    ))}
            </div>
        </div>
    );
}

export default DeviceList;
