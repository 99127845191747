import {Checkbox} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import "./VoicemailList.css";
import VoicemailRow from "../VoicemailRow/VoicemailRow";
import {cancelMessage, showMessage} from "../../app/utils";
import * as api from "../../app/api";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SettingsIcon from "@material-ui/icons/Settings";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedVoicemails, getVoicemails, setVoicemails, setSelectedVoicemails} from "../../features/voicemailSlice";
import {RefreshSharp} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DeleteIcon from "@material-ui/icons/Delete";

function VoicemailList({pageSize}) {
    const voicemails = useSelector(getVoicemails);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showVoicemailsDelete, setShowVoicemailsDelete] = useState(false);
    const selectedVoicemails = useSelector(getSelectedVoicemails);

    let lastVoicemailDate = voicemails?.length > 0 ? voicemails[voicemails.length - 1].date : new Date().getTime();
    let firstVoicemailDate = voicemails?.length > 0 ? voicemails[0].date : new Date().getTime();

    const checkDates = () => {
        if (voicemails.length > 0) {
            lastVoicemailDate = voicemails[voicemails.length - 1].date;
            firstVoicemailDate = voicemails[0].date;
        }
    }

    const loadNextVoicemails = () => {
        checkDates();
        showMessage("Loading voicemails...")
        console.log("Loading next voicemails from " + new Date(lastVoicemailDate))
        api.loadVoicemailsAfter(pageSize, lastVoicemailDate, function (response) {
            cancelMessage()
            if (response.voicemails?.length > 0) {
                dispatch(setVoicemails(response.voicemails.sort((a, b) => b.date - a.date)));
                checkDates();
            } else {
                showMessage("No more voicemails")
            }
        })
    }

    const loadPreviousVoicemails = () => {
        checkDates();
        showMessage("Loading voicemails...")
        console.log("Loading previous voicemails from " + new Date(firstVoicemailDate))
        api.loadVoicemailsBefore(pageSize, firstVoicemailDate, function (response) {
            cancelMessage()
            if (response.voicemails?.length > 0) {
                dispatch(setVoicemails(response.voicemails.sort((a, b) => b.date - a.date)));
                checkDates();
            } else {
                showMessage("No more voicemails")
            }
        })
    }

    const reload = () => {
        showMessage("Loading voicemails...")
        console.log("reloading voicemails")
        api.loadVoicemailsAfter(pageSize, (firstVoicemailDate || new Date().getTime()) + 1, function (response) {
            cancelMessage()
            if (response.voicemails?.length > 0) {
                dispatch(setVoicemails(response.voicemails.sort((a, b) => b.date - a.date)));
                checkDates();
            } else {
                showMessage("No voicemails")
            }
        })
    }


    const deleteVoicemails = () => {
        setShowVoicemailsDelete(true);
    }

    const cancelDelete = () => {
        setShowVoicemailsDelete(false);
    }

    const doDelete = () => {
        setShowVoicemailsDelete(false);
        if (!selectedVoicemails || selectedVoicemails.length === 0) {
            console.log('no voicemails to delete')
            return;
        }

        let voicemailIds = ""

        selectedVoicemails.forEach(call => {
            voicemailIds += call + ","
        })

        api.deleteVoicemails(voicemailIds, () => {
            let voicemailsNotDeleted = voicemails.filter(voicemail => voicemailIds.indexOf(voicemail.id) < 0);
            dispatch(setVoicemails(voicemailsNotDeleted));
            dispatch(setSelectedVoicemails([]));
        });

    }

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            let allVoicemails = voicemails.map(voicemail => voicemail.id);
            dispatch(setSelectedVoicemails(allVoicemails));
        } else {
            dispatch(setSelectedVoicemails([]));
        }
    }

    useEffect(() => reload(), [pageSize])

    return (
        <div className="voicemailList">
            <div className="page-settings">
                <div className="page-settingsLeft">
                    <Button><Checkbox onChange={handleSelectAll}/> Select all</Button>
                    { selectedVoicemails && selectedVoicemails.length > 0 && <Button onClick={deleteVoicemails}>
                        <DeleteIcon/> Delete
                    </Button>}
                    <Button onClick={reload}>
                        <RefreshSharp/> Refresh
                    </Button>
                </div>
                <div className="page-settingsRight">
                    <Button onClick={() => loadPreviousVoicemails()}>
                        <ChevronLeftIcon/> Previous Page
                    </Button>
                    <Button onClick={() => loadNextVoicemails()}>
                        Next Page<ChevronRightIcon/>
                    </Button>
                    <Button onClick={() => history.push("/settings")}>
                        <SettingsIcon/> Settings
                    </Button>
                </div>
            </div>
            <h2 className="app-title">Voicemails</h2>
            <div className="voicemailList-list">
                {voicemails.map((voicemail) => (
                    <VoicemailRow
                        voicemails={voicemails}
                        setVoicemails={setVoicemails}
                        key={voicemail.id}
                        id={voicemail.id}
                        sid={voicemail.call_id}
                        number={voicemail.from}
                        date={voicemail.date}
                        duration={voicemail.duration}
                        transcription={voicemail.text}
                        recording={voicemail.url}
                        read={voicemail.read}
                    />
                ))}
            </div>
            {showVoicemailsDelete &&  <Dialog
                open={showVoicemailsDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Delete Voicemails"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete these voicemails?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDelete}>Cancel</Button>
                    <Button onClick={doDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>}
        </div>
    );
}

export default VoicemailList;
