import {createSlice} from "@reduxjs/toolkit";

export const contactSlice = createSlice({
    name: "contact",
    initialState: {
        contact: null,
        contacts: [],
    },
    reducers: {
        setContact: (state, action) => {
            state.contact = action.payload;
        },
        setContacts: (state, action) => {
            state.contacts = action.payload;
        },
    },
});

export const {setContacts, setContact} = contactSlice.actions;
export const getContact = (state) => state.contact.contact;
export const getContacts = (state) => state.contact.contacts;
export default contactSlice.reducer;
