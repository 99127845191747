import React, {useState} from "react";
import "./Sidebar.css";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import SidebarOption from "./SidebarOption";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    Contacts, HeadsetMic,
    SmartphoneOutlined, Twitter,
    Voicemail
} from "@material-ui/icons";
import {getVoicemails} from "../../features/voicemailSlice";
import SettingsIcon from "@material-ui/icons/Settings";
import IncomingCall from "../Call/IncomingCall";
import {Divider} from "@material-ui/core";
import {FacebookSharp, GraphicEq} from "@mui/icons-material";
import {selectUser} from "../../features/userSlice";
import * as api from "../../app/api";

function Sidebar({incomingCall, setIncomingCall}) {
    const voicemails = useSelector(getVoicemails);
    const [active, setActive] = useState("/");
    const user = useSelector(selectUser);

    const unreadVoicemails = () => {
        let count = 0;
        voicemails?.forEach(function (voicemail) {
            if (voicemail?.read === false) {
                count++;
            }
        })
        return count;
    }

    return (
        <div className="sidebar">
            <div className="sidebar-user" style={{backgroundImage: `url(${user?.photoUrl})`}}>
                <div className="sidebar-user-content-bg">
                    <div className="sidebar-user-content">
                        <span className="sidebar-user-name">{user?.displayName}</span>
                        <br/>
                        <span className="sidebar-user-email">{user?.email}</span>
                    </div>
                </div>
            </div>
            <div className="sidebar-logo">
                <a target="_blank" rel="noreferrer" href="https://callassistant.ai">
                    <img
                        className="header-logo-top"
                        src="https://admin.callassistant.app/images/ca_logo_text_blue.png"
                        alt="call assistant logo"
                    />
                </a>
                <br/>
                <div className="header-terms">
                    v{api.versionId}
                </div>
            </div>
            <br/>
            <IncomingCall incomingCall={incomingCall} setIncomingCall={setIncomingCall}/>

            {incomingCall && <Link to="/incoming" className="sidebar-link"
                  onClick={() => {setActive("/incoming");}}
            >
                <SidebarOption
                    Icon={PhoneIcon}
                    title="Incoming Call"
                    selected={active === "/incoming"}
                />
            </Link>}
            <Link to="/" className="sidebar-link"
                  onClick={() => {setActive("/");}}
            >
                <SidebarOption
                    Icon={PersonIcon}
                    title="Account"
                    selected={active === "/"}
                />
            </Link>
            <Link to="/calls" className="sidebar-link"
                  onClick={() => {setActive("/calls");}}
            >
                <SidebarOption
                    Icon={PhoneIcon}
                    title="Calls"
                    selected={active === "/calls"}
                />
            </Link>
            <Link to="/assistant" className="sidebar-link"
                  onClick={() => {setActive("/assistant");}}
            >
                <SidebarOption
                    Icon={HeadsetMic}
                    title="Assistant"
                    selected={active === "/assistant"}
                />
            </Link>
            <Link to="/voicemails" className="sidebar-link"
                  onClick={() => {setActive("/voicemails");}}
            >
                <SidebarOption
                    Icon={Voicemail}
                    title="Voicemails"
                    number={unreadVoicemails()}
                    selected={active === "/voicemails"}
                />
            </Link>
            <Link to="/contacts" className="sidebar-link"
                  onClick={() => {setActive("/contacts");}}
            >
                <SidebarOption
                    Icon={Contacts}
                    title="Contacts"
                    selected={active === "/contacts"}
                />
            </Link>
            <Link to="/greetings" className="sidebar-link"
                  onClick={() => {setActive("/greetings");}}
            >
                <SidebarOption
                    Icon={GraphicEq}
                    title="Greetings"
                    selected={active === "/greetings"}/>
            </Link>
            <Link to="/devices" className="sidebar-link"
                  onClick={() => {setActive("/devices");}}
            >
                <SidebarOption
                    Icon={SmartphoneOutlined}
                    title="Devices"
                    selected={active === "/devices"}/>
            </Link>
            <Link to="/settings" className="sidebar-link"
                  onClick={() => {setActive("/settings");}}
            >
                <SidebarOption
                    Icon={SettingsIcon}
                    title="Settings"
                    selected={active === "/settings"}
                />
            </Link>
            <Divider/>
            <a href="https://www.facebook.com/callassistantai/" target="_blank" rel="noreferrer" className="sidebar-link">
                <SidebarOption
                    Icon={FacebookSharp}
                    title="Facebook"
                />
            </a>
            <a href="https://twitter.com/Callassistapp" target="_blank" rel="noreferrer" className="sidebar-link">
                <SidebarOption
                    Icon={Twitter}
                    title="Twitter"
                />
            </a>
        </div>
    );
}

export default Sidebar;
