import React from "react";
import "./GreetingList.css";
import SettingsIcon from "@material-ui/icons/Settings";
import GreetingRow from "../GreetingRow/GreetingRow";
import {useHistory} from "react-router-dom";
import * as api from "../../app/api";
import Loading from "../../Loading";
import {useDispatch, useSelector} from "react-redux";
import {getGreetings, setGreeting, setGreetings} from "../../features/greetingSlice";
import {Refresh} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import ContactSelector from "../ContactList/ContactSelector";
import Button from "@mui/material/Button";

function GreetingList({session}) {
    const history = useHistory();
    const greetings = useSelector(getGreetings);

    const [loading, setLoading] = React.useState(true);
    const [showSelectNumber, setShowSelectNumber] = React.useState(false);
    const dispatch = useDispatch();

    function addGreeting(number) {
        setShowSelectNumber(false)
        dispatch(setGreeting(defaultGreeting(number)))
        history.push("/greeting")
    }
    function fetchGreetings() {
        setLoading(true);
        api.getGreetings().then(data =>  {
            setLoading(false);

            let newGreetings = data?.greetings
            if (newGreetings == null || newGreetings.length <= 0) {
                newGreetings = [defaultGreeting()];
            }

            dispatch(setGreetings(newGreetings))
        })
    }

    function defaultGreeting(number) {

        let g = null;
        greetings?.forEach( greeting => {
            if (!greeting.number && !number) {
                g = greeting
            }
        })
        if (g) {
            return g;
        }

        g = {}
        g.number = number
        g.voice = "Joanna"
        g.language = "en-US"
        g.mode = "text"
        g.text = "Hello " + session.name + ' is not available. Please leave a message after the beep'
        return g;
    }

    function fetchGreeting(id) {
        setLoading(true);
        api.getGreetingById(id).then(data => {
            dispatch(setGreeting(data?.greeting))
            setLoading(false);
            setTimeout(() => history.push("/greeting"), 200)
        });
    }

    React.useEffect(() => {
        fetchGreetings();
    }, []);


    return (
        <div className="greetingList">
            <div className="page-settings">
                <div className="page-settingsLeft">
                    <Button onClick={() => fetchGreetings()}>
                        <Refresh/> Refresh
                    </Button>
                    <Button onClick={() => setShowSelectNumber(true)}>
                        <AddIcon/> Add Greeting
                    </Button>
                </div>
                <div className="page-settingsRight">
                    <Button onClick={() => history.push("/settings")}>
                        <SettingsIcon/> Settings
                    </Button>
                </div>
            </div>

            {showSelectNumber &&<ContactSelector setShowSelectNumber={setShowSelectNumber} onNumberSelected={(number) => addGreeting(number)}/>}

            <h2 className="app-title">Greetings</h2>
            <div className="greetings">
            <div className="greetingList-list">
                { loading && <Loading type="bars"/>}
                {(greetings == null || greetings.length === 0) && <span className="callList-no-calls">No Greetings</span>}
                {
                    greetings.map((greeting) => (
                        <GreetingRow
                            key={greeting.id}
                            greeting={greeting}
                            reload={fetchGreetings}
                            onClick={() => fetchGreeting(greeting.id)}
                        />
                    ))}

            </div>
            </div>
        </div>
    );
}

export default GreetingList;
