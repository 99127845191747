import {Button} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {login} from "../../features/userSlice";
import {appleProvider, auth, provider, signInWithPopup} from "../../firebase";
import "./Login.css";
import {Terms} from "../../App";
import {showMessage} from "../../app/utils";
import {IconButton} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";

function Login() {
    const dispatch = useDispatch();
    const [video, setVideo] = useState(false);

    const signIn = () => {
        signInWithPopup(auth, provider).then(({user}) => {
            dispatch(
                login({
                    displayName: user.displayName,
                    email: user.email,
                    photoUrl: user.photoURL,
                })
            );
        });
    };
    const watchVideo = () => {

    };

    const appleIdSignin = () => {
        let appleSignIn = appleProvider
        appleSignIn.addScope('email');
        appleSignIn.addScope('name');
        signInWithPopup(auth, appleSignIn).then(({user}) => {
            console.log("Apple ID sign in successful")
            dispatch(
                login({
                    displayName: user.displayName,
                    email: user.email,
                    photoUrl: user.photoURL,
                })
            );
        }).catch((error) => {
            // Handle Errors here.
            let errorCode = error.code;
            let errorMessage = error.message;
            // The email of the user's account used.
            let email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            let credential = error.credential;

            showMessage("Apple Sign In", "Unable to sign in with Apple ID: " + errorMessage);
        });

    }

    function Video() {
        const hideVideo = () => {
            setVideo(false);
        }
        return (<div className="video-player-wrapper">
            <video className="video-player" autoPlay={true} onEnded={hideVideo} onClick={hideVideo}>
                <source src="https://www.callassistant.ai/wp-content/uploads/2022/08/call-assistant-video-ios.mp4"
                        type="video/mp4"/>
            </video>
            <IconButton className="video-close" onClick={hideVideo}>
                <CloseIcon/>
            </IconButton></div>);
    }


    return (
        <div className="login">
            <div className="login-container">
                <img className="login-logo"
                     width="300"
                     src="https://admin.callassistant.app/images/ca_logo_text_blue.png"
                     alt=""
                />
                <div style={{'text-align':'center'}}>
                <img src="/images/google_signin.png" onClick={signIn} style={{width: '207px'}}/>
                    <br/>
                    <br/>
                <img src="/images/apple_signin.png" onClick={appleIdSignin} width={200}/>
                <br/>
                    <br/>
                    <Button style={{width: '200px'}} className={"watch-video"} variant="contained" color="default" onClick={() => setVideo(true)}>
                    Watch Video
                </Button>
                <br/>
                <br/>
                <br/>
                <Terms/>
                </div>

            </div>
            {video && <Video/>}
        </div>
    );
}

export default Login;
