import {
    Button, IconButton, TextField
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import "./Settings.css";
import * as api from "../../app/api";
import {cancelMessage, showMessage} from "../../app/utils";
import {WcSharp, ManSharp, WomanSharp} from "@mui/icons-material";
import {SaveAlt} from "@material-ui/icons";

function General({setPendingChanges}) {

    const [settings, setSettings] = useState(null);

    const updateGender = (gender) => {
        let s = {...settings} || {}
        s.gender = gender;
        setPendingChanges(true)
        setSettings(s);
    }
    const updateName = (event) => {
        let s = {...settings} || {}
        s.name = event.target.value;
        setPendingChanges(true)
        setSettings(s);
    }
    const updateSettings = () => {
        if (!settings?.name) {
            showMessage("Please add a name");
            return;
        }
        if (settings?.gender === undefined) {
            showMessage("Please add a name");
            return;
        }
        showMessage("Updating settings...")
        console.log("updating settings", settings);
        api.updateSettings(settings, function () {
            cancelMessage()
            setPendingChanges(false)
            showMessage("Settings updated successfully")
        });
    }
    useEffect(() => {
        api.getSettings(function (result) {
            console.log("settings", result);
            setSettings(result);
        })
    }, []);

    return (<div>
        <br/>
        <br/>
        <br/>
                <div className="settings-container">
                    <br/>
                    <br/>
                    {settings && <TextField
                        className="settings-name"
                        onChange={updateName}
                        placeholder={"Type your name"}
                        defaultValue={settings?.name}
                        label="Name"
                    ></TextField>}
                    <br/>
                    <br/>
                    <br/>
                    <h4>Gender</h4>
                    <br/>
                    <div className="settings-gender">
                        <IconButton onClick={() => updateGender("M")}
                                color={settings?.gender === "M" ? "secondary" : "default"}
                        >
                            <ManSharp />
                        </IconButton>
                        <IconButton onClick={() => updateGender("F")}
                                color={settings?.gender === "F" ? "secondary" : "default"}>
                            <WomanSharp />
                        </IconButton>
                        <IconButton onClick={() => updateGender("")}
                                color={settings?.gender === "" ? "secondary" : "default"}>
                            <WcSharp />
                        </IconButton>
                    </div>
                    <br/>
                    <br/>
                    <Button onClick={() => updateSettings()}><SaveAlt/>&nbsp;Update Settings</Button>
                </div>
    </div>);
}

export default General;
