import Button from "@mui/material/Button";
import React from "react";
import "./ContactList.css";
import {getContacts} from "../../features/contactSlice";
import {useSelector} from "react-redux";
import {ClickAwayListener} from "@material-ui/core";
import {getInternationalNumber} from "../../app/utils"
function ContactSelector({setShowSelectNumber, onNumberSelected}) {

    const contacts = useSelector(getContacts)

    function filteredContacts() {
        let query = "Cuca"
        return contacts.filter(contact => {
            return true;
        })
    }

    return (
        <ClickAwayListener onClickAway={() => setShowSelectNumber(false)}><div className="contact-selector bg">
            <Button className="contact-selector-close" onClick={() => setShowSelectNumber(false)}>Close</Button>
            <h2 className="app-title">Select Number</h2>

            <div className="contactList-list">
                {(contacts == null || contacts.length === 0) && <span className="contactList-no-contacts">No Contacts</span>}
                {
                    filteredContacts().map((contact) => (<div className="select-number-item">
                        <span>{contact.name || getInternationalNumber(contact.numbers[0])}</span>
                        {
                            contact.numbers.map((number) => (
                            <Button onClick={() => onNumberSelected(number)}>{getInternationalNumber(number)}</Button>
                            ))
                        }
                        </div>
                    ))}
            </div>
        </div></ClickAwayListener>
    );
}

export default ContactSelector;
