import React from "react";
import "./Call.css";

function CallMessage({message}) {
    const dateFormatted = new Date(message?.date).toLocaleString()

    return (
        <div className="call-msg">
            {message.assistant && <span className="call-msg-title">Assistant said:</span>}
            {!message.assistant && <span className="call-msg-title">Caller {message.partial ? "is saying" : "said"}:</span>}
            <br/>
            <span className="call-msg-text">{message.message}</span>
            <br/>
            <span className="call-msg-time">{dateFormatted}</span>
        </div>
    );
}

export default CallMessage;
