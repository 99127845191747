import {
    IconButton,

} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import "./Settings.css";
import SettingsIcon from "@material-ui/icons/Settings";
import {PhoneSharp, VoicemailSharp} from "@material-ui/icons";
import Section from "../Section/Section";
import Greeting from "./Greeting";
import General from "./General";
import Buttons from "./Buttons";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";


function Settings() {

    const history = useHistory();
    const [currentTab, setCurrentTab] = useState("general");
    const [pendingChanges, setPendingChanges] = useState(false);
    const [changeTab, setChangeTab] = useState(null);

    const moveToTab = (tab) => {
        if (pendingChanges) {
            setChangeTab(tab);
        } else {
            setPendingChanges(false);
            setCurrentTab(tab);
        }
    }

    function PendingChangesDialog() {

        const cancelTab = () => {
            setChangeTab(null);
        };

        const continueToTab = () => {
            setCurrentTab(changeTab);
            setChangeTab(null);
            setPendingChanges(false);
        };

        return (
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Pending Changes"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        There are pending changes that were not saved. Do you want to continue without saving?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={continueToTab}>Continue</Button>
                    <Button onClick={cancelTab}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    useEffect(() => {

    }, []);

    return (
        <div className="settings">
            <div className="page-settings">
                <div className="page-settingsLeft">
                </div>
                <div className="page-settingsRight">
                    <IconButton>
                        <SettingsIcon/>
                    </IconButton>
                </div>
            </div>
            <h2 className="app-title">Settings</h2>
            <div className="settings-sections">
                <Section onClick={() => moveToTab("general")}
                         Icon={SettingsIcon}
                         title="General"
                         color="#ff4444"
                         selected={currentTab === "general" }/>
                <Section onClick={() => history.push("/greetings")}
                         Icon={VoicemailSharp}
                         selected={currentTab === "greeting" }
                         title="Greeting"
                         color="#1A73E8"/>
                <Section onClick={() => history.push("/assistant")}
                         Icon={PhoneSharp}
                         selected={currentTab === "buttons" }
                         title="Buttons"
                         color="#20ac4e"/>
            </div>

            {currentTab === "general" && <General setPendingChanges={setPendingChanges}/>}
            {currentTab === "greeting" && <Greeting setPendingChanges={setPendingChanges}/>}
            {currentTab === "buttons" && <Buttons pendingChanges={pendingChanges} setPendingChanges={setPendingChanges}/>}
            {changeTab &&<PendingChangesDialog/>}
        </div>);
}

export default Settings;
