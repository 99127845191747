import {initializeApp} from 'firebase/app';
import {getFirestore, collection, query, getDocs, setDoc, deleteDoc, addDoc} from 'firebase/firestore/lite';
import {getMessaging, getToken} from 'firebase/messaging';
import {getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider} from 'firebase/auth';
import {getRemoteConfig, fetchAndActivate} from 'firebase/remote-config';
import {getAnalytics} from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyAA5lFl8wV5MWjCbZ6CvfkTmsTVZ0H6JA8",
    authDomain: "call-asistant.firebaseapp.com",
    databaseURL: "https://call-asistant.firebaseio.com",
    projectId: "call-asistant",
    storageBucket: "call-asistant.appspot.com",
    messagingSenderId: "387042158367",
    appId: "1:387042158367:web:4f776812a5dfe88d14322a",
    measurementId: "G-JV922B57CT"
};


const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp)
const messaging = getMessaging(firebaseApp)
const rc = getRemoteConfig(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp)
const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com')
let lastListener = null;

export const registerMessageListener = (listener) => {
    if (navigator && navigator.serviceWorker) {
        if (lastListener) {
            navigator.serviceWorker.removeEventListener('message', lastListener)
        }
        navigator.serviceWorker.addEventListener('message', listener);
        lastListener = listener
    }
}
export {
    db,
    auth,
    provider,
    appleProvider,
    messaging,
    firebaseApp,
    rc,
    analytics,
    signInWithPopup,
    getToken,
    fetchAndActivate,
    collection,
    query,
    getDocs,
    setDoc,
    deleteDoc,
    addDoc
};
