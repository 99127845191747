import React, {useEffect, useRef, useState} from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import {BrowserRouter as Router, Switch, Route, useHistory} from "react-router-dom";
import Call from "./components/Call/Call";
import SendMail from "./components/SendMail/SendMail";
import {useSelector} from "react-redux";
import {selectSendMessageIsOpen} from "./features/mailSlice";
import {login} from "./features/userSlice";
import Login from "./components/Login/Login";
import VoicemailList from "./components/VoicemailList/VoicemailList";
import DesktopNotification from "./components/DesktopNotification/NotificationDialog"
import * as api from "./app/api";
import {registerMessageListener, auth} from "./firebase";
import CallList from "./components/CallList/CallList";
import ContactList from "./components/ContactList/ContactList";
import {useDispatch} from "react-redux";
import Account from "./components/Account/Account";
import DeviceList from "./components/DeviceList/DeviceList";
import NumberList from "./components/NumberList/NumberList";
import {showMessage, cancelMessage, extractNumber, getE164Number, isMobileNumber} from "./app/utils"
import {Toaster} from "react-hot-toast";
import Voicemail from "./components/Voicemail/Voicemail";
import WindowFocusHandler from "./components/WindowsFocusHandler/WindowsFocusHandler";
import {
    getStream,
    setCalls,
    updateDevice,
    updateStream,
} from "./features/callSlice";
import {setVoicemails} from "./features/voicemailSlice";
import Settings from "./components/Settings/Settings";
import {Device} from "twilio-client";
import FullIncomingCall from "./components/Call/FullIncomingCall";
import PCMPlayer from "pcm-player";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {Button, TextField} from "@material-ui/core";
import DialogActions from "@mui/material/DialogActions";
import {verifyNumberTwilio, verifyNumber, verifyCode as sendSmsCode} from "./app/api";
import PinInput from "react-pin-input";
import Subscription from "./components/Subscription/Subscription";
import GreetingList from "./components/GreetingList/GreetingList";
import Greeting from "./components/Settings/Greeting";
import Buttons from "./components/Settings/Buttons";
import ButtonEditor from "./components/Settings/ButtonEditor";
import {getContacts, setContacts} from "./features/contactSlice";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

function App() {
    /**
     * Calculate the number of rows per page
     * @returns {number} number of rows per page
     */
    const calculatePageSize = () => Math.ceil((window.innerHeight - 200) / 60);
    const dispatch = useDispatch()
    const sendMessageIsOpen = useSelector(selectSendMessageIsOpen);
    const stream = useSelector(getStream);
    const [session, setSession] = useState(null);
    const [user, setUser] = useState(null);
    const [player, setPlayer] = useState(null);
    const [desktopNotification, setDesktopNotification] = React.useState(false)
    const [pageSize, setPageSize] = useState(calculatePageSize());
    //const [incomingCall, setIncomingCall] = useState({number: "+351967120226", state: "screening"})
    const [incomingCall, setIncomingCall] = useState(null)
    // show/hide dialog to add/verify a new number
    const [showAddNumber, setShowAddNumber] = useState(false);
    // number that will be calling the number that is being verified
    const [verificationCall, setVerificationCall] = useState(null);
    // show/hide dialog that inputs the SMS code received when verifying a number via sms
    const [verifySms, setVerifySms] = useState(false);
    // show/hide dialog that displays the verification code when verifying a number via call
    const [verifyCode, setVerifyCode] = useState(false);
    const [pendingChanges, setPendingChanges] = useState(false);

    /**
     * Sort contacts by name
     * @param contactsList list of contacts
     */
    const sortContacts = (contactsList) => {
        contactsList.sort(function (a, b) {
            let x = (a.name || "").toLowerCase();
            let y = (b.name || "").toLowerCase();
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
            return 0;
        });

    }

    function checkNotificationsPermission() {
        if (!window.Notification) {
            showMessage("Error", "This browser is not supported by Voicemail Assistant.");
        } else {
            if (window.safari) {
                // requestSafariPermission()
                // return;
            }
            if (Notification.permission === "granted") {
                return true;
            }
            console.log("show desktop notification")
            setDesktopNotification(true)
            return false;
        }
    }

    /**
     * Check and load contacts
     * @returns {boolean} true if contacts found or false if no contacts are found
     */

    function checkContacts() {
        let loadedContactsList = window.localStorage.getItem('contacts_list');
        let loadedContacts = window.localStorage.getItem('contacts');
        let storedContacts;
        let storedContactsList;
        if (loadedContacts) {
            try {
                storedContactsList = JSON.parse(loadedContactsList);
                storedContacts = JSON.parse(loadedContacts);
                console.log("storedContacts")
                console.log(storedContacts)
                console.log("storedContactsList")
                console.log(storedContactsList)
                if (!storedContacts || Object.keys(storedContacts).length === 0 || !storedContactsList || storedContactsList.length === 0) {
                    return false;
                } else {
                    sortContacts(storedContactsList)
                    dispatch(setContacts(storedContactsList));
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
        }
        return false;
    }

    const setupTwilioDevice = () => {
        const device = new Device();
        device.on('incoming', connection => {
            console.log('incoming call', connection);
            if (incomingCall) {
                console.log('ignoring incoming call, active call present', incomingCall);
                return;
            }
            const callSid = connection.customParameters.get("parent_call");
            const n = extractNumber(connection.parameters.From);
            setIncomingCall({number: n, incoming: connection, state: "ringing", callSid: callSid});
            //history.push("/incoming");
        });

        device.on('ready', device => {
            console.log("twilio device ready")
        });

        device.on('connect', connection => {
            console.log("twilio device connected", connection);
            if (incomingCall) {
                setIncomingCall({...incomingCall, state: "connected", connection: connection})
            }
        });
        device.on('disconnect', connection => {
            console.log("twilio device disconnected", connection);
            setIncomingCall(null);
        });

        dispatch(updateDevice(device))

        api.getTwilioToken(null, (result) => {
            device.setup(result.token);
        })

    }

    /**
     * Setup Firebase Authentication
     */
    function firebaseAuth() {
        console.log("Firebase Auth setup")
        auth.onAuthStateChanged(function (user) {
            if (user) {
                if (api.isLoggedIn()) {
                    console.log("Already logged in")
                    return;
                }
                console.log("Firebase Auth Signed in")

                // User is signed in.
                // var displayName = user.displayName;
                // var email = user.email;
                // var emailVerified = user.emailVerified;
                // var photoURL = user.photoURL;
                // var isAnonymous = user.isAnonymous;
                // var uid = user.uid;
                // var providerData = user.providerData;

                user.getIdToken().then(function (accessToken) {
                    console.log("Firebase token " + accessToken)

                    if (!checkNotificationsPermission()) {
                        return;
                    }
                    if (accessToken && !api.isLoggedIn()) {
                        let toastId = showMessage('Signing in, please wait...')
                        api.start(accessToken, api.FIREBASE, function (data) {
                            cancelMessage(toastId);
                            showMessage('Signed in', 'Loading data, please wait...')
                            setSession(data.session);
                            let u = {
                                displayName: user.displayName,
                                email: user.email,
                                photoUrl: user.photoURL,
                            }
                            setUser(u)
                            dispatch(
                                login(u)
                            );
                            if (!checkContacts()) {
                                if (api.getLastDevice()) {
                                    showMessage("Requesting contacts, check your phone...")
                                    api.requestContacts(function (contacts) {
                                        sortContacts(contacts)
                                        dispatch(setContacts(contacts));
                                    });
                                }
                            }
                            api.loadCalls(pageSize, function (result) {
                                dispatch(setCalls(result.calls));
                            });
                            api.loadVoicemails(pageSize, function (result) {
                                dispatch(setVoicemails(result.voicemails));
                            });

                            setupTwilioDevice();

                        });
                    }
                });

            } else {
                // User is signed out.
                setUser(null)
            }
        });
    }

    /**
     * Called when window is resized
     */
    const handleResize = () => {
        let ps = calculatePageSize();
        console.log('new page size ' + ps);
        setPageSize(ps);
    }

    useEffect(() => {

        if (Notification.permission === "granted") {
            api.initFirebaseMessaging()
        }
        firebaseAuth();

        window.addEventListener('resize', handleResize)
    }, []);

    /**
     * Created this element to handle firebase messages.
     * Can't use useHistory() on App element
     * @returns {JSX.Element}
     * @constructor
     */
    function FirebaseMessageHandler({incomingCall, setIncomingCall}) {
        const history = useHistory();

        function newCall() {
            let from = "+351967120344";
            emulateMessage({
                "call_action": "screen",
                "action": "call_action",
                "from": from,
                "id": "49a81cfd-2326-5cc3-6213-b391b8f1ff62",
                "text": "Hi, Paulo can't take your call, how can I help? ",
                "lang": "en-US",
                "call_id": "CA2844dd34a2729347dc6b16f72041e9e4"
            }, 1000);
            emulateMessage({
                "screener_id": "8eab1528-5e99-462e-ab84-fed6bdcb249d",
                "partial": true,
                "action": "screener",
                "from": from,
                "text": "Hi"
            }, 2000);
            emulateMessage({
                "screener_id": "8eab1528-5e99-462e-ab84-fed6bdcb249d",
                "partial": true,
                "action": "screener",
                "from": from,
                "text": "Hi hello"
            }, 3000);
            emulateMessage({
                "screener_id": "8eab1528-5e99-462e-ab84-fed6bdcb249a",
                "partial": true,
                "action": "screener",
                "from": from,
                "text": "Hi hello, this is a test"
            }, 4000);
            emulateMessage({
                "screener_id": "8eab1528-5e99-462e-ab84-fed6bdcb249q",
                "partial": true,
                "action": "screener",
                "from": from,
                "text": "Hello this is a test"
            }, 5000);
            emulateMessage({
                "screener_id": "8eab1528-5e99-462e-ab84-fed6bdcb2422",
                "partial": false,
                "action": "screener",
                "from": from,
                "text": "Hi How are you?"
            }, 7000);
        }

        const emulateMessage = (msg, timeout) => {
            setTimeout(function () {
                handleCallAssistantMessage(msg)
            }.bind(this), timeout);
        }

        /**
         * Handle event
         * @param event FCM message
         */
        const firebaseMessageListener = (event) => {

            try {
                let data = event.data.data;
                if (data && data.message) {
                    // from FCM while app is running in the background
                    let msg = JSON.parse(data.message);
                    handleCallAssistantMessage(msg);
                } else {
                    // from service worker
                    handleCallAssistantMessage(event.data);
                }
            } catch (e) {
                console.log(e.message, event.data);
            }
        }

        let lastChunk = -1;

        /**
         * initialize audio stream
         */

        const handleStreamStart = (msg) => {
            // intialize audio player wrapper
            const p = new PCMPlayer({
                encoding: '16bitInt',
                channels: 1,
                sampleRate: 8000,
                flushingTime: 1000
            });
            setPlayer(p);
            lastChunk = 0;
            navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                })
                .then(function (s) {
                    dispatch(updateStream(s));
                })
                .catch(function (err) {
                    console.log(err.message);
                });

            // analyser = player.audioCtx.createAnalyser();
            // analyser.minDecibels = -90;
            // analyser.maxDecibels = -10;
            // analyser.smoothingTimeConstant = 0.85;
            // canvasCtx = canvas.getContext("2d");
            // if (navigator.mediaDevices.getUserMedia) {
            //     console.log('getUserMedia supported.');
            //     let constraints = {audio: true}
            //     // setup audio visualizer
            //     navigator.mediaDevices.getUserMedia (constraints)
            //         .then(
            //             function(stream) {
            //                 let gainNode = player.gainNode;
            //                 player.gainNode.gain.value = 0
            //                 let biquadFilter = player.audioCtx.createBiquadFilter();
            //                 let convolver = player.audioCtx.createConvolver();
            //                 biquadFilter.connect(gainNode);
            //                 convolver.connect(gainNode);
            //                 gainNode.connect(analyser);
            //                 analyser.connect(player.audioCtx.destination);
            //                 visualize();
            //                 voiceChange();
            //             })
            //         .catch( function(err) { console.log('The following gUM error occured: ' + err);})
            // } else {
            //     console.log('getUserMedia not supported on your browser!');
            // }
        }
        const handleStreamMedia = (msg) => {
            /**
             * Convert base64 data to byte array
             * @param base64 base64 string
             * @returns {Uint8Array} decoded array
             * @private
             */
            function _base64ToArrayBuffer(base64) {
                let binary_string = window.atob(base64);
                let len = binary_string.length;
                let bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
                return bytes;
            }

            // base64 encoded data
            let payload = msg.payload;
            // message chunk
            let chunk = msg.chunk;
            if (chunk <= lastChunk) {
                // skip old chunk
                return;
            }
            lastChunk = chunk;
            let track = msg.track; // just inbound (for now)
            // decode data
            let data = _base64ToArrayBuffer(payload)
            if (!player) {
                handleStreamStart();
            }
            // play data
            player.feed(data);
        }

        /**
         * destroy audio player
         */

        const handleStreamStop = (msg) => {
            lastChunk = 0;
            if (player) {
                console.log("destroying player...")
                player.destroy();
                setPlayer(null)
            }
            if (stream) {
                stream.getTracks().forEach(function (track) {
                    track.stop();
                })
            }

        }
        /**
         * Handles number verification message
         * @param msg json payload message
         */
        const handleVerification = (msg) => {
            setShowAddNumber(false);
            setVerificationCall(null);
            setVerifyCode(false);
            showMessage(msg.title);
            console.debug('verification result', msg);
            if (msg.verification_status === "success") {
                api.getSession((s) => {
                    console.debug('new session after verification', s)
                    setSession(s);
                })
            }
        }
        /**
         * Handles broadcast messages from other devices (phone, tablet, browser)
         * @param msg json payload message
         */
        const handleBroadcast = (msg) => {
            if (msg.broadcast === "call") { // received call event from device
                if (msg.state === "ringing") { // call is ringing on device
                    console.debug("verification call " + verificationCall);
                    if (verificationCall && verificationCall === msg.number) {
                        // this call is expected to verify call we should ignore
                        setVerificationCall(null);
                        return;
                    }
                    setIncomingCall({number: msg.number, clientId: msg.client_id, state: "ringing"});
                    history.push("/incoming");
                }
                if (msg.state === "idle" && incomingCall && incomingCall.state === "ringing") { //  phone on hook
                    setIncomingCall(null);
                }
            } else {
                api.handleMessage(msg);
            }

        }

        /**
         * Handle messages being screened in realtime
         * @param msg json payload message         */
        const handleScreener = (msg) => {
            let messages = incomingCall?.messages?.filter(m => m.screenerId !== msg.screener_id) || [];
            let newMessages = [...messages, {
                partial: msg.partial,
                message: msg.text,
                assistant: false,
                date: new Date(),
                screenerId: msg.screener_id
            }];
            setIncomingCall({...incomingCall, messages: newMessages, number: msg.from, state: "screening"});
            history.push("/incoming");
        }

        /**
         * Handles messages when Twilio calls hangup up
         * @param msg json payload message
         * */
        const handleTwilioHangup = (msg) => {
            let callSid = msg.call_id;
            if (incomingCall?.callSid === callSid) {
                setIncomingCall(null);
            }
        }

        /**
         * Handles messages when a call action is processed and acknowledged by the server
         * @param msg json payload message
         * */
        const handleCallAction = (msg) => {
            if (msg.call_action === "screen") {
                let messages = incomingCall?.messages || [];
                let callSid = msg.call_id;
                let newMessages = [...messages, {
                    partial: false,
                    message: msg.text,
                    assistant: true,
                    date: new Date(),
                    screenerId: msg.id
                }];
                setIncomingCall({
                    ...incomingCall,
                    language: msg.lang,
                    voice: msg.voice,
                    messages: newMessages,
                    number: msg.from,
                    state: "screening",
                    callSid: callSid
                });
            }
        }

        /**
         * Handles messages when a call is received.
         * The caller will still hear the ringing sound
         * and we show the incoming call screen. the user can still interact with the caller
         * @param msg json payload message
         * */
        const handleRecordVoicemail = (msg) => {
            if (!incomingCall) {
                setIncomingCall({
                    number: msg.number,
                    state: "ringing",
                    callSid: msg.call_id
                });
                history.push("/incoming");
            }
        }

        /**
         * Handles a fcm messages
         * @param msg json payload message
         * */

        const handleCallAssistantMessage = (msg) => {
            console.debug('handling message', msg);
            if (msg.action === "broadcast") {
                handleBroadcast(msg);
                return;
            }
            if (msg.action === "verification") {
                handleVerification(msg);
                return;
            }
            if (msg.action === "call_action") {
                handleCallAction(msg);
                return;
            }
            if (msg.action === "record_voicemail") {
                handleRecordVoicemail(msg);
                return;
            }
            if (msg.action === "screener") {
                handleScreener(msg);
                return;
            }
            if (msg.action === "twilio_hangup") {
                handleTwilioHangup(msg);
                return;
            }
            if (msg.action === "stream_media") {
                handleStreamMedia(msg);
                return;
            }
            if (msg.action === "stream_start") {
                handleStreamStart(msg);
                return;
            }
            if (msg.action === "stream_stop") {
                handleStreamStop(msg);
                return;
            }
            api.handleMessage(msg)

        }

        useEffect(() => {
            // register FCM handler. Should only be called one
            registerMessageListener(firebaseMessageListener);
        }, []);

        return (<></>)

    }

    function AddNumber() {
        const numberField = useRef();
        const codeField = useRef();
        /**
         * Hide add number dialog
         */
        const doCancel = () => {
            setShowAddNumber(false);
            setVerifyCode(false);
            setVerificationCall(null);
            setVerifySms(false);
        }
        /**
         * Send SMS verification code
         */
        const sendVerificationCode = () => {
            const value = codeField.current.values.join('')
            if (value.length < 4) {
                showMessage('Invalid code');
                return;
            }
            sendSmsCode(value)
                .then((data) => {
                    if (data.status === "OK") {
                        setVerifyCode(false);
                        setShowAddNumber(false);
                        setVerifySms(false);
                        showMessage("Number verified successfully");
                        api.getSession((s) => {
                            console.debug('new session after sms verification', s)
                            setSession(s);
                        })
                    } else {
                        showMessage(data.error);
                    }
                })
        }
        /**
         * start add number procedure
         */
        const doAddNumber = () => {
            const number = numberField.current?.value;
            if (number) {
                if (isMobileNumber(number, session.country)) {
                    // mobile numbers use the SMS method to verify numbers
                    verifyNumber(getE164Number(number, session.country))
                        .then((data) => {
                            if (data.status === 'OK') {
                                setVerifySms(true);
                            }
                        })
                } else {
                    // non mobile numbers use the call method to verify numbers
                    verifyNumberTwilio(getE164Number(number, session.country))
                        .then((data) => {
                            if (data.verification_code) {
                                dispatch(() => {
                                    setVerificationCall(data.from);
                                    setVerifyCode(data.verification_code);
                                });
                            } else {
                                showMessage(data.message);
                                setShowAddNumber(false);
                            }
                        });
                }
            }
        }

        return (<Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {"Add Number"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Add a new number to your account
                </DialogContentText>
                <br/>
                {!verifyCode && !verifySms && <TextField
                    type="tel"
                    autoFocus={true}
                    inputRef={numberField}
                    className="settings-name"
                    placeholder={"Mobile or landline"}
                    label="Number"
                ></TextField>}
                {verifyCode && <div className="verify-code-block">Please answer the incoming call and type this code: <span
                        className="verify-code">{verifyCode}</span></div>}
                {verifySms && <div className="verify-code-block">Please insert the code from the SMS
                    <br/>
                        <PinInput length={4}
                                  focus={true}
                                  initialValue=""
                                  ref={codeField}
                                  type="numeric"
                                  inputMode="number"
                                  style={{padding: '10px'}}
                                  inputStyle={{borderColor: '#1a52c9', fontSize: '16pt'}}
                                  inputFocusStyle={{borderColor: 'blue'}}
                                  onComplete={(value, index) => {}}
                                  autoSelect={true}/></div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => doCancel()}>{verifyCode ? "Done" : "Cancel"}</Button>
                {!verifyCode && !verifySms && <Button onClick={() => doAddNumber()}>
                    Add Number
                </Button>}
                {verifySms && <Button onClick={() => sendVerificationCode()}>
                    Send
                </Button>}
            </DialogActions>
        </Dialog>)
    }

    return (
        <DndProvider backend={HTML5Backend}>
        <Router>
            <FirebaseMessageHandler incomingCall={incomingCall} setIncomingCall={setIncomingCall}/>
            {!user ? (
                <div>
                    <Login/>
                    {desktopNotification && <DesktopNotification desktopNotification={desktopNotification}
                                                                 setDesktopNotification={setDesktopNotification}/>}
                </div>
            ) : (
                <div className="app">
                    <div className="app-body">
                            <Sidebar incomingCall={incomingCall} setIncomingCall={setIncomingCall}/>
                            <div  className="app-content">
                                <Header/>
                            <Switch>
                            <Route path="/" exact>
                                <Account session={session} user={user} setShowAddNumber={setShowAddNumber}/>
                            </Route>
                                <Route path="/assistant" exact>
                                    <Buttons session={session} setPendingChanges={setPendingChanges} pendingChanges={pendingChanges}/>
                                </Route>
                                <Route path="/button" exact>
                                    <ButtonEditor session={session} setPendingChanges={setPendingChanges} pendingChanges={pendingChanges}/>
                                </Route>
                                <Route path="/calls" exact>
                                    <CallList pageSize={pageSize}/>
                                </Route>
                            <Route path="/call">
                                <Call/>
                            </Route>
                            <Route path="/incoming">
                                <FullIncomingCall incomingCall={incomingCall} setIncomingCall={setIncomingCall}/>
                            </Route>
                            <Route path="/devices" exact>
                                <DeviceList session={session}/>
                            </Route>
                            <Route path="/numbers" exact>
                                <NumberList session={session}/>
                            </Route>
                                <Route path="/voicemails" exact>
                                    <VoicemailList pageSize={pageSize}/>
                                </Route>
                                <Route path="/greeting" exact>
                                    <Greeting />
                                </Route>
                                <Route path="/greetings" exact>
                                    <GreetingList session={session} pageSize={pageSize}/>
                                </Route>
                            <Route path="/voicemail" exact>
                                <Voicemail/>
                            </Route>
                            <Route path="/contacts" exact>
                                <ContactList pageSize={pageSize}/>
                            </Route>
                            <Route path="/subscription" exact>
                                <Subscription/>
                            </Route>
                            <Route path="/settings" exact>
                                <Settings/>
                            </Route>
                        </Switch>
                        </div>
                    </div>

                    {sendMessageIsOpen && <SendMail/>}
                    {desktopNotification && <DesktopNotification desktopNotification={desktopNotification}
                                                                 setDesktopNotification={setDesktopNotification}/>}

                    {showAddNumber && <AddNumber/>}
                    <Toaster/>
                    <WindowFocusHandler/>
                </div>
            )}
        </Router>
        </DndProvider>
    );
}

export function Terms() {
    return (
        <div className="menu_bottom">
            <a title="Terms &amp; Conditions" rel="noreferrer" href="https://callassistant.ai/terms" target="_blank">
                Terms
            </a>
            &nbsp;|&nbsp;
            <a title="Privacy policy" rel="noreferrer" href="https://callassistant.ai/privacy" target="_blank">
                Privacy Policy
            </a>
            &nbsp;
            <a target="_blank" rel="noreferrer" href="https://callassistant.ai">
                <img alt="Logo" className="logo_bottom_menu" src="/images/call_assistant.png"/>
            </a>
            &nbsp;
            <span className="logo_callassistant_bottom">CallAssistant</span><span className="logo_dotai">.ai</span>
            &nbsp;
            v<span className="version_label">{api.versionId}</span>
        </div>)
}

export default App;
