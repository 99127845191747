import React from "react";
import "./Section.css";

function Section({Icon, title, color, selected, onClick}) {
    return (
        <div
            onClick={onClick}
            className={`section ${selected && "section--selected"}`}
            style={{
                cursor: "pointer",
                borderBottom: `3px solid ${color}`,
                color: `${selected && color}`,
            }}
        >
            <Icon/>
            <span>{title}</span>
        </div>
    );
}

export default Section;
