import {
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    TextField, IconButton
} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import "./Settings.css";
import * as api from "../../app/api";
import {languages, showMessage, getInternationalNumber} from "../../app/utils";
import {Add, ArrowLeft, CheckBox, CheckBoxOutlineBlank, PlayArrow, SaveAlt} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {getGreeting, setGreeting} from "../../features/greetingSlice";
import SettingsIcon from "@material-ui/icons/Settings";
import {useHistory} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Loading from "../../Loading";
import * as utils from "../../app/utils";
import {findContact, generateGreetingVoicemail, isOK} from "../../app/api";
import ContactSelector from "../ContactList/ContactSelector";
import DeleteIcon from "@material-ui/icons/Delete";


function Greeting({setPendingChanges, number}) {

    const history = useHistory();
    const greeting = useSelector(getGreeting);
    const [defaultGreeting, setDefaultGreeting] = useState(false);
    const [voices, setVoices] = useState([]);
    const [selectNumber, setSelectNumber] = useState(false);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const audioRef = useRef();

    const handleGreetingText = (event) => {
        console.log("greeting text changed " + event.target.value);
        let g = {...greeting};
        g.text = event.target.value
        if (setPendingChanges) setPendingChanges(true)
        dispatch(setGreeting(g));
    }

    const handleVoiceChange = (event) => {
        console.log("voice changed " + event.target.value);
        let g = {...greeting};
        g.voice = event.target.value;
        if (setPendingChanges) setPendingChanges(true)
        dispatch(setGreeting(g));
    };
    const handleLanguageChange = (event) => {
        console.log("language changed " + event.target.value);
        let g = {...greeting};
        g.language = event.target.value
        dispatch(setGreeting(g));
        if (setPendingChanges) setPendingChanges(true)
        loadVoices(event.target.value);
    };
    const playing = (event) => {
        setLoading(false)
    }
    const playGreeting = (event) => {
        setLoading(true)
        let url = api.server + "/api/tts?text=" + encodeURIComponent(greeting.text) + "&voice_id=" + encodeURIComponent(greeting.voice);
        audioRef.current.setAttribute("src", url);
        audioRef.current.play();
    }

    function updateDefaultGreeting(event) {
        let g = {...greeting}
        g.defaultGreeting = !g.defaultGreeting;
        if (setPendingChanges) setPendingChanges(false)
        dispatch(setGreeting(g));

    }

    const updateGreeting = (event) => {
        console.log("updating greeting", greeting);
        showMessage('Updating greeting')
        if (greeting.text.length > 500) {
            showMessage('Greeting message is too long');
            return;
        }
        if (greeting.text && greeting.language && greeting.voice) {
            api.updateGreeting(greeting)
            .then( data => {
                utils.log("update greeting", data);
                if (!isOK(data)) {
                    return;
                }
                utils.recordAnalyticsEvent("Web", "greeting", "update");
                history.goBack()
            });
            if (!greeting.number) {
                // set default voice
                api.updateSettings({"assistant_voice_id": greeting.voice})
            }
        }
        if (setPendingChanges) setPendingChanges(false)
    }

    const loadVoices = (language) => {
        showMessage("Loading voices...");
        setVoices(null);
        api.getVoicesByLang(language || "en-US", function (result) {
            console.log("voices loaded", result);
            if (greeting && greeting.language !== language) {
                let g = {...greeting};
                g.language = language;
                g.voice = result[0].voice_id;
                setGreeting(g);
            }
            setVoices(result);
        });
    }

    function title() {
        let c = findContact(greeting.number);
        if (c) {
            return c.name + " (" + getInternationalNumber(greeting.number) + ")";
        }
        return getInternationalNumber(greeting.number);
    }

    function GreetingNumber(number) {
        let c = findContact(number);
        if (c) {
            return (<div><span className="greeting-number-title">{c.name}</span><br/><span className="greeting-number-number">{getInternationalNumber(number)}</span></div>)
        }
        return (<span className="greeting-number-title">{getInternationalNumber(number)}</span>)
    }

    function addNumber(number) {
        let g = {...greeting}
        if (greeting.numbers) {
            if (greeting.numbers.indexOf(number) >= 0) {
                return
            }
            g.numbers = [...g.numbers, number]
        } else {
            greeting.numbers = [number]
        }
        setSelectNumber(false)
        dispatch(setGreeting(g))
    }

    function arrayRemove(arr, value) {

        return arr.filter(function(ele){
            return ele != value;
        });
    }

    function removeNumber(number) {
        let g = {...greeting}
        if (greeting.numbers) {
            if (greeting.numbers.indexOf(number) >= 0) {
                g.numbers = arrayRemove(g.numbers, number)
            }
        }
        setSelectNumber(false)
        dispatch(setGreeting(g))
    }


    useEffect(() => {
        if (!greeting) {
            history.goBack();
            return
        }
        debugger
        setDefaultGreeting(greeting.defaultGreeting)
        loadVoices(greeting?.language || "en-US");
    }, []);

    function doGenerateGreeting() {
        setLoading(true);
        generateGreetingVoicemail(greeting.language).then(result => {
            console.log(result);
            if (result.greeting) {
                let g = {...greeting};
                g.text = result.greeting
                if (setPendingChanges) setPendingChanges(true)
                dispatch(setGreeting(g));
            }
            setLoading(false)
        })
    }

    function GreetingDefault() {
        if (greeting && greeting.defaultGreeting) {
            return <CheckBox/>
        }
        return (<CheckBoxOutlineBlank/>)
    }

    return (<div className="fadeIn">
            <div className="page-settings">
                <div className="page-settingsLeft">
                    <IconButton onClick={() => history.push("/greetings")}>
                        <ArrowBackIcon/>
                    </IconButton>
                </div>
                <div className="page-settingsRight">
                    <IconButton onClick={() => history.push("/settings")}>
                        <SettingsIcon/>
                    </IconButton>
                </div>
            </div>

        {greeting?.number == null && <h2 className="app-title">Greeting for all calls</h2>}
        {greeting?.number != null && <h2 className="app-title">Greeting for number {title()}</h2>}

        <br/>
        <br/>
            <div className="greeting-container">
                <div className="greeting-content">
                        <div className="greeting-text-container">
                        {greeting && <FormControl>
                            <TextField
                                className="greetingText"
                                id="greeting-text"
                                placeholder="Type your greeting"
                                label="Message"
                                multiline
                                minRows={4}
                                onChange={handleGreetingText}
                                value={greeting?.text || ""}
                            />

                        </FormControl>}
                        <br/>
                        <Button className="generate-greeting-voicemail" onClick={() => doGenerateGreeting()}>Generate Greeting</Button>
                        </div>
                        {greeting && <FormControl>
                            <InputLabel id="languages-label">Languages</InputLabel>
                            <Select
                                labelId="languages-label"
                                id="languages"
                                defaultValue={greeting?.language || ""}
                                autoWidth={true}
                                onChange={handleLanguageChange}
                            >
                                {languages.map((l) => (
                                    <MenuItem key={l.id} value={l.id}
                                              selected={l.id === greeting?.language}>{l.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                        <br/>
                        <br/>
                        {voices?.length > 0 && greeting && <FormControl>
                            <InputLabel id="voices-label">Voices</InputLabel>
                            <Select
                                className="greetingText"
                                labelId="voices-label"
                                id="voices"
                                onChange={handleVoiceChange}
                                defaultValue={greeting?.voice || ""}
                                autoWidth={true}
                            >
                                {voices.map((v) => (
                                    <MenuItem key={v.voice_id} value={v.voice_id}
                                              selected={v.voice_id === greeting.voice}>{v.voice}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        }
                        <br/>
                        <br/>
                    {!defaultGreeting && <Button onClick={updateDefaultGreeting}><GreetingDefault/>&nbsp;Default</Button>}
                        <br/>
                        <br/>
                        <Button onClick={playGreeting}><PlayArrow/>&nbsp;Play Greeting</Button>
                        <Button onClick={updateGreeting}><SaveAlt/>&nbsp;Update Greeting</Button>
                        <audio ref={audioRef} onPlaying={playing}></audio>
                       </div>
                { greeting && greeting.numbers && <div className="greeting-numbers">
                    <h2 className="greeting-numbers-title">Numbers</h2>
                    {greeting.numbers.map((number) => (
                        <div className="greeting-number" key={number}>
                            {GreetingNumber(number)}
                            {greeting.numbers.length > 1 && <Button onClick={() => removeNumber(number)}><DeleteIcon/>&nbsp;&nbsp;Delete</Button>}
                        </div>
                    ))}
                    {selectNumber &&<ContactSelector setShowSelectNumber={setSelectNumber} onNumberSelected={(number) => addNumber(number)}/>}
                    <br/>
                    <Button onClick={() => setSelectNumber(true)}><Add/> Add Number</Button>

                </div>
                }
            </div>
        {loading && <Loading/>}
    </div>);
}

export default Greeting;
