import React, {useState} from "react";
import ReactAudioPlayer from "react-audio-player";
import "./Call.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintIcon from "@material-ui/icons/Print";
import {IconButton} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {selectOpenCall, removeCall} from "../../features/callSlice";
import {useSelector, useDispatch} from "react-redux";
import CallMessage from "./CallMessage";
import {deleteCall, findContact, sessionInfo} from "../../app/api";
import * as libphonenumber from "libphonenumber-js";
import {ShareRounded} from "@material-ui/icons";
import PhoneIcon from "@material-ui/icons/Phone";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {DownloadSharp} from "@mui/icons-material";
import {Divider} from "@mui/material";
import {getRemoteConfigBoolean} from "../../app/utils";

function Call() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [showDelete, setShowDelete] = useState(null);
    const selectedCall = useSelector(selectOpenCall);

    const cancelDelete = () => {
        setShowDelete(false);
    }
    const deleteClicked = () => {
        setShowDelete(true);

    }
    const doDelete = () => {
        setShowDelete(false);
        deleteCall(selectedCall.id, function () {
            dispatch(removeCall(selectedCall))
            history.push("/calls");
        })
    }

    if (!selectedCall) {
        history.goBack();
        return null;
    }

    const getRecordingUrl = () => {
        if (selectedCall) {
            if (getRemoteConfigBoolean("mp3")) {
                let url = new URL(selectedCall.recording);
                return url.origin + url.pathname + ".mp3";
            }
            return selectedCall.recording;
        }
    }

    const dateFormatted = () => new Date(selectedCall?.date || 0).toLocaleString();

    const title = () => {

        let name = findContact(selectedCall?.number)?.name

        let t = selectedCall?.number;
        try {
            let n = libphonenumber.parse(selectedCall?.number)
            t = libphonenumber.format(n, n.country === sessionInfo?.country ? 'NATIONAL' : 'INTERNATIONAL')
        } catch (e) {
            console.log(e)
        }

        if (name) {
            return name + " (" + t + ")";
        }

        return t
    }
    const download = () => {
        window.open(getRecordingUrl(), "_blank");
    }

    return (
        <div className="call">
            <div className="call-tools">
                <div className="call-tools-left">
                    <IconButton onClick={() => history.push("/calls")}>
                        <ArrowBackIcon/>
                    </IconButton>

                    {selectedCall.recording && <Button onClick={download}>
                        <DownloadSharp/> Download
                    </Button>}

                    <Button onClick={deleteClicked}>
                        <DeleteIcon/> Delete
                    </Button>

                    <Button onClick={() => window.print()}>
                        <PrintIcon/>&nbsp;&nbsp;Print
                    </Button>
                </div>
                <div className="call-tools-right">

                </div>
            </div>
            <div className="call-body">
                <div className="call-bodyHeader">
                    <div className="call-subject">
                        <IconButton>
                            <PhoneIcon/>
                        </IconButton>
                        <div className="call-subject-content">
                        <h2>{title()}</h2>
                        <span className="call-time">{dateFormatted()}</span>
                        </div>
                    </div>
                    <div className="call-tools-right">
                        {selectedCall?.recording &&
                            <div>
                                <IconButton className="call-button-right">
                                    <ShareRounded/>
                                </IconButton>
                                <ReactAudioPlayer src={getRecordingUrl()} controls/>
                            </div>
                        }
                    </div>
                </div>
                <Divider/>
                <div className="call-content">
                {(selectedCall?.messages == null || selectedCall?.messages?.length === 0) &&
                    <span className="contactList-no-contacts">No Messages</span>}
                {
                    selectedCall?.messages?.length > 0 && selectedCall?.messages?.map((message) => (
                        <CallMessage key={Math.random()} message={message}/>
                    ))}
                </div>
            </div>
            {showDelete &&  <Dialog
                open={showDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete this call?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDelete}>Cancel</Button>
                    <Button onClick={doDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>}
        </div>
    );
}

export default Call;
