import {createSlice} from "@reduxjs/toolkit";

export const voicemailSlice = createSlice({
    name: "voicemail",
    initialState: {
        selectedVoicemail: null,
        voicemails: [],
        selectedVoicemails: [],
    },
    reducers: {
        setVoicemails: (state, action) => {
            state.voicemails = action.payload;
        },

        selectVoicemail: (state, action) => {
            state.selectedVoicemail = action.payload;
        },

        removeVoicemail: (state, action) => {
            if (state.selectedVoicemail && state.voicemails) {
                const newVoicemails = state.voicemails.filter(voicemail => voicemail.id !== state.selectedVoicemail.id)
                if (newVoicemails.length !== state.voicemails.length) {
                    state.voicemails = newVoicemails;
                }
            }
        },
        removeSelectedVoicemail: (state, action) => {
            console.log('removing voicemail', action.payload);
            const newSelectedVoicemails = state.selectedVoicemails.filter(voicemail => {
                return voicemail !== action.payload.id
            });
            state.selectedVoicemails = newSelectedVoicemails;
        },
        addSelectedVoicemail: (state, action) => {
            console.log('adding voicemail', action.payload);
            const exists = state.selectedVoicemails.filter(voicemail => voicemail === action.payload.id)
            if (exists && exists.length > 0) {
                return;
            }
            const newSelectedVoicemails = [...state.selectedVoicemails, action.payload.id];
            state.selectedVoicemails = newSelectedVoicemails;
        },

        setSelectedVoicemails: (state, action) => {
            state.selectedVoicemails = action.payload;
        },

    },
});

export const {selectVoicemail, removeVoicemail, setVoicemails, removeSelectedVoicemail, addSelectedVoicemail, setSelectedVoicemails} = voicemailSlice.actions;
export const selectOpenVoicemail = (state) => state.voicemail.selectedVoicemail;
export const getVoicemails = (state) => state.voicemail.voicemails;
export const getSelectedVoicemails = (state) => state.voicemail.selectedVoicemails;
export default voicemailSlice.reducer;
