import {
    Button,
    Card,
    CardActions, CardContent,
    CardHeader, CardMedia,
    IconButton,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import "./Subscription.css";
import SettingsIcon from "@material-ui/icons/Settings";
import * as api from "../../app/api";
import * as utils from "../../app/utils";
import {useHistory} from "react-router-dom";
import {CreditCard} from "@material-ui/icons";
import {showMessage} from "../../app/utils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const REMOTE_CONFIG_STRIPE_PLAN_YEAR = "stripe_plan_yearly"
const REMOTE_CONFIG_STRIPE_PLAN_MONTH = "stripe_plan_monthly"
const REMOTE_CONFIG_STRIPE_CHECKOUT = "stripe_checkout_url"
const REMOTE_CONFIG_STRIPE_TEST_MODE = "stripe_test_mode"



function Subscription() {
    const [monthlyPlan, setMonthlyPlan] = useState(null)
    const [yearlyPlan, setYearlyPlan] = useState(null)
    const [error, setError] = useState(null)

    const history = useHistory();
    const [subscription, setSubscription] = useState(null)
    const monthly = utils.getRemoteConfigString(REMOTE_CONFIG_STRIPE_PLAN_MONTH);
    const yearly = utils.getRemoteConfigString(REMOTE_CONFIG_STRIPE_PLAN_YEAR);
    const stripeTestMode = utils.getRemoteConfigBoolean(REMOTE_CONFIG_STRIPE_TEST_MODE, false);

    const buySubscription = (plan) => {
        function goToCheckout(session) {
            const checkout = utils.getRemoteConfigString(REMOTE_CONFIG_STRIPE_CHECKOUT);
            window.location.href = checkout + "?session=" + session;
        }

        const url = '/api/stripe/session?plan=' + encodeURIComponent(plan) + "&test=" + stripeTestMode;

        showMessage('Loading', 'Redirecting to Stripe please wait...');

        api.apiCall2(url, null, null)
            .then(data => {
                if (data.status === "OK") {
                    goToCheckout(data.session);
                } else {
                    setError("An error occurred, please try again later");
                }
            });
    }

    const loadPlans = () => {
        let url = '/api/stripe/plan?id=' + encodeURIComponent(monthly) + "&id=" + encodeURIComponent(yearly) + "&test=" + stripeTestMode;
        // load premium plans
        api.apiCall2(url, null, null)
            .then(data => {
                console.log("subscription plans", data);
                if (data.status === "OK") {
                    setError(null);
                    for (let i = 0; i < data.plans.length; i++) {
                        let plan = data.plans[i];
                        if (plan.interval === "month") {
                            setMonthlyPlan(plan);
                        } else {
                            setYearlyPlan(plan);
                        }
                    }
                } else {
                    setError("An error occurred, please try again later")
                }
            });
    }
    useEffect(() => {
        function validateSubscription(s) {
            if (!s) {
                setSubscription(null);
                return;
            }
            if (s.subscriber) {
                let entitlements = s.subscriber.entitlements;
                let existing;

                if (entitlements && entitlements.subscription) {
                    existing = entitlements.subscription;
                }
                setSubscription({
                    expires_date: existing?.expires_date,
                    product_identifier: existing?.product_identifier,
                    purchase_date: existing?.purchase_date,
                })
            } else {
                setSubscription(null)
            }
        }

        const checkSubscription = () => {
            api.getSubscription((response) => {
                validateSubscription(response.subscription);
            });
        }
        checkSubscription();
        loadPlans();
    }, []);

    return (
        <div className="account">
            <div className="page-settings">
                <div className="page-settingsLeft">
                    <IconButton onClick={() => history.push("/")}>
                        <ArrowBackIcon/>
                    </IconButton>
                </div>
                <div className="page-settingsRight">
                    <IconButton onClick={() => history.push("/settings")}>
                        <SettingsIcon/>
                    </IconButton>
                </div>
            </div>
            {/*<div className="callList-sections">*/}
            {/*    <Section Icon={InboxIcon} title="Primary" color="red" selected/>*/}
            {/*    <Section Icon={PeopleIcon} title="Social" color="#1A73E8"/>*/}
            {/*    <Section Icon={LocalOfferIcon} title="Promotions" color="green"/>*/}
            {/*</div>*/}


            <h2 className="app-title">Subscription</h2>
            <br/>
            {error && <span>{error}</span>}
            {!error && monthlyPlan && yearlyPlan && <div className="callList-list">
                <div className="subscription-items">
                    <Card style={{width: 345, margin: 20}}>
                        <CardHeader
                            avatar={
                                <CreditCard/>
                            }
                            action={
                                <div className="subscription-price"><br/>${monthlyPlan.amount / 100}</div>
                            }
                            subheader="Monthly"
                            title="Call Assistant Subscription"
                        />
                        <CardActions disableSpacing>
                            <Button onClick={() => buySubscription(monthly)}>Buy</Button>
                        </CardActions>
                    </Card>
                    <Card style={{width: 345, margin: 20}}>
                        <CardHeader
                            avatar={
                                <CreditCard/>
                            }
                            action={
                                <div className="subscription-price"><br/>${yearlyPlan.amount / 100}</div>
                            }
                            subheader="Yearly"
                            title="Call Assistant Subscription"
                        />
                        <CardActions disableSpacing>
                            <Button onClick={() => buySubscription(yearly)}>Buy</Button>
                        </CardActions>
                    </Card>
                </div>
                <br/>
                <br/>
                <div className="subscription-content">
                    <br/>
                    <br/>
                    <h2 style={{textAlign: "center"}}>What can I do with a Call Assistant Subscription?</h2>
                    <br/>
                    <br/>
                    <div className="subscription-items">
                        <Card className="subscription-card">
                            <CardHeader
                                title="Screen Calls"
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image="https://www.callassistant.ai/wp-content/uploads/2022/03/iphone_screener-1024x689.png"
                                alt="Screen Calls"
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Let Call Assistant take your calls while you read what the caller is saying in realtime and later decide if you want to answer the call
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className="subscription-card">
                            <CardHeader
                                title="Visual Voicemails"
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image="https://www.callassistant.ai/wp-content/uploads/2022/08/iphone_notifications.png"
                                alt="Voicemails"
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Enjoy a state of the art voicemail system. You can listen, share or download all your voicemails.
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className="subscription-card">
                            <CardHeader
                                title="Hold Music"
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image="https://www.callassistant.ai/wp-content/uploads/2022/07/call_assistant_hold_music_small-1024x689.png"
                                alt="Hold Music"
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Put callers on hold and select what music you want callers to listen from Spotify
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <br/>
                    <br/>
                    <div className="subscription-items">
                        <Card className="subscription-card">
                            <CardHeader
                                title="Block Calls"
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image="https://www.callassistant.ai/wp-content/uploads/2022/07/call_assistant_incoming_call_small-1024x689.png"
                                alt="Block Calls"
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Block calls from selected numbers and allow Call Assistant to automatically block numbers that have been marked as Spam
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className="subscription-card">
                            <CardHeader
                                title="Personalization"
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image="https://www.callassistant.ai/wp-content/uploads/2022/07/call_assistant_personalize_small-1024x689.png"
                                alt="Personalization"
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Call Assistant allows extensive personalisation of the assistant for your own needs
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className="subscription-card">
                            <CardHeader
                                title="Download"
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image="https://www.callassistant.ai/wp-content/uploads/2022/08/call-assistant-fight-spam.png"
                                alt="Download"
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Download Call Assistant now from the <a href="https://play.google.com/store/apps/details?id=com.callassistant.android" target="_blank" rel="noreferrer">Play Store</a>
                                    or <a href="https://apps.apple.com/us/app/call-assistant-ai/id1554266174" target="_blank" rel="noreferrer">App Store</a>.
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>}
        </div>);
}

export default Subscription;
