import React, {useState} from "react";
import ReactAudioPlayer from "react-audio-player";
import "./Voicemail.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintIcon from "@material-ui/icons/Print";
import {IconButton} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {deleteVoicemail, findContact, sessionInfo} from "../../app/api";
import * as libphonenumber from "libphonenumber-js";
import {ShareRounded, VoicemailRounded} from "@material-ui/icons";
import {selectOpenVoicemail, removeVoicemail} from "../../features/voicemailSlice";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {DownloadSharp} from "@mui/icons-material";
import {Divider} from "@mui/material";

function Voicemail() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showDelete, setShowDelete] = useState(null);

    const selectedVoicemail = useSelector(selectOpenVoicemail);

    const cancelDelete = () => {
        setShowDelete(false);
    }
    const deleteClicked = () => {
        setShowDelete(true);
    }
    const doDelete = () => {
        setShowDelete(false);
        deleteVoicemail(selectedVoicemail.id, function () {
            dispatch(removeVoicemail(selectedVoicemail))
            history.push("/voicemails");
        })
    }

    if (!selectedVoicemail) {
        history.goBack();
        return null;
    }

    const dateFormatted = new Date(selectedVoicemail?.date || 0).toLocaleString();

    const title = () => {

        let name = findContact(selectedVoicemail?.number)?.name

        let t = selectedVoicemail?.number;
        try {
            let n = libphonenumber.parse(selectedVoicemail?.number)
            t = libphonenumber.format(n, n.country === sessionInfo?.country ? 'NATIONAL' : 'INTERNATIONAL')
        } catch (e) {
            console.log(e)
        }

        if (name) {
            return name + " (" + t + ")";
        }

        return t
    }

    const download = () => {
        window.open(selectedVoicemail.recording, "_blank");
    }

    return (
        <div className="voicemail">
            <div className="voicemail-tools">
                <div className="voicemail-tools-left">
                    <IconButton onClick={() => history.push("/voicemails")}>
                        <ArrowBackIcon/>
                    </IconButton>

                    { selectedVoicemail.recording && <Button onClick={download}>
                        <DownloadSharp/> Download
                    </Button> }

                    <Button onClick={deleteClicked}>
                        <DeleteIcon/> Delete
                    </Button>

                    <Button onClick={() => window.print()}>
                        <PrintIcon/>&nbsp;&nbsp;Print
                    </Button>
                </div>
                <div className="voicemail-tools-right">
                </div>
            </div>
            <div className="voicemail-body">
                <div className="voicemail-bodyHeader">
                    <div className="voicemail-subject">
                        <IconButton>
                            <VoicemailRounded/>
                        </IconButton>

                        <div className="voicemail-subject-content">
                        <h2>{title()}</h2>
                        <span className="voicemail-time">{dateFormatted}</span>
                        </div>
                    </div>
                    <div className="voicemail-tools-right">
                        {selectedVoicemail?.recording &&
                            <div>
                                <IconButton className="voicemail-button-right">
                                    <ShareRounded/>
                                </IconButton>
                                <ReactAudioPlayer src={selectedVoicemail.recording} controls/>
                            </div>
                        }
                    </div>
                </div>
                <Divider/>
                <div className="voicemail-content">
                {(selectedVoicemail?.transcription == null || selectedVoicemail?.transcription?.length === 0) &&
                    <span className="contactList-no-contacts">No Message</span>}
                { selectedVoicemail?.transcription?.length > 0 &&
                    <span>{selectedVoicemail.transcription}</span>
                }
                </div>

            </div>

            {showDelete &&  <Dialog
                open={showDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete this voicemail?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDelete}>Cancel</Button>
                    <Button onClick={doDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>}
        </div>
    );
}

export default Voicemail;
