import {Checkbox, IconButton} from "@material-ui/core";
import React from "react";
import "./CallRow.css";
import {useHistory} from "react-router-dom";
import {selectCall, addSelectedCall, removeSelectedCall, getSelectedCalls} from "../../features/callSlice";
import {useDispatch, useSelector} from "react-redux";
import * as libphonenumber from "libphonenumber-js"
import {sessionInfo, findContact} from "../../app/api"
import PhoneIcon from "@material-ui/icons/Phone";

function CallRow({id, sid, number, date, duration, type, transcription, video, messages, recording, spam}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedCalls = useSelector(getSelectedCalls);

    const dateFormatted = new Date(date).toLocaleString();

    const title = () => {

        let name = findContact(number)?.name

        let t = number;
        try {
            let n = libphonenumber.parse(number)
            t = libphonenumber.format(n, n.country === sessionInfo?.country ? 'NATIONAL' : 'INTERNATIONAL')
        }catch (e) {
            console.log(e)
        }

        if (!t) {
            t = number;
        }

        if (name) {
            return name + " (" + t + ")";
        }

        return t
    }

    const handleCallChecked = (event) => {
        let checked = event.target.checked;

        if (checked) {
            dispatch(addSelectedCall({id: id}));
        } else {
            dispatch(removeSelectedCall({id: id}));
        }

    }

    const durationString = () => {
        let seconds = duration % 60;
        if (duration > 60) {
            return Math.floor(duration / 60) + "m " + seconds + "s"
        } else {
            return seconds + "s"
        }
    }
    const openCall = () => {
        dispatch(
            selectCall({
                id,
                sid,
                number,
                date,
                duration,
                type,
                transcription,
                video,
                messages,
                recording
            })
        );
        history.push("/call");
    };

    const isSelected = () => {
        let found = selectedCalls?.find(call => call === id);
        return found && found.length > 0;
    }

    return (
        <div className="itemRow">
            <div className="itemRow-options">
                { isSelected() && <Checkbox onChange={handleCallChecked} defaultChecked/>}
                { !isSelected() && <Checkbox onChange={handleCallChecked}/>}
                <IconButton onClick={openCall}>
                    <PhoneIcon/>
                </IconButton>
            </div>
            <h3 onClick={openCall} className="callRow-title">{title()}</h3>
            <div onClick={openCall} className="callRow-message">
                { spam && <span className="callRow-spam">SPAM</span>}
                <h4>
                    <span className="callRow-description">{transcription || ""}</span>
                </h4>
            </div>
            <p className="callRow-time">{durationString()}</p>
            <p className="callRow-time">{dateFormatted}</p>
        </div>
    );
}

export default CallRow;
