import {IconButton} from "@material-ui/core";
import React from "react";
import "./DeviceRow.css";
import {AndroidOutlined, Apple, LaptopChromebook} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import {getClientId} from "../../app/utils";

function DeviceRow({id, type, ip, brand, device, manufacturer, model, name, operator, versionId, version, lastActivity}) {
    return (
        <div className="itemRow">
            <div className="itemRow-options">
                <IconButton>
                    {type === "android" && <AndroidOutlined/>}
                    {type === "ios" && <Apple/>}
                    {type === "web" && <LaptopChromebook/>}
                </IconButton>
            </div>
            <div className="deviceRow-title">
                <b>{manufacturer} - {model}</b>
                <br/>
                {brand} - version {versionId}
                {operator && <span><br/>Operator: {operator}</span>}
                <br/>
                Last updated: {new Date(lastActivity).toLocaleString()}
            </div>
            {id !== getClientId() && <div className="deviceRow-message">
                <IconButton>
                    <DeleteIcon/>
                </IconButton>
            </div>}
        </div>
    );
}

export default DeviceRow;
