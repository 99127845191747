import {createSlice} from "@reduxjs/toolkit";

export const greetingSlice = createSlice({
    name: "greeting",
    initialState: {
        greeting: null,
        greetings: [],
    },
    reducers: {
        setGreetings: (state, action) => {
            state.greetings = action.payload;
        },
        setGreeting: (state, action) => {
            state.greeting = action.payload;
        },
    },
});

export const {setGreetings, setGreeting} = greetingSlice.actions;
export const getGreetings = (state) => state.greeting.greetings;
export const getGreeting = (state) => state.greeting.greeting;
export default greetingSlice.reducer;
