import * as utils from "./utils";
import {log, showMessage} from "./utils";
import {
    messaging,
    db,
    rc,
    getToken,
    fetchAndActivate,
    collection,
    query,
    getDocs,
    deleteDoc,
    addDoc, auth
} from "../firebase";

// current web version
const CA_WEB_VERSION = 0.82;
export const versionId = "0.3.3";

// backend url
const prodServer = "https://api.callassistantapp.com";
//export const server = "https://admin.callassistant.app";
export const devServer = "https://pt.eu.ngrok.io";

const useDevServer = false;

export const server = (useDevServer && process.env.NODE_ENV === 'development') ? devServer : prodServer;


// API Token
let token;
// current session
export let sessionInfo;

export const CALL_ASSISTANT = 0;
export const FACEBOOK = 1;
export const GOOGLE = 2;
export const FIREBASE = 6;
export const APPLE = 7;
export const SALESFORCE = 8;

const publicVapidKey = "BLP3qYFYQ0LGlPcvYeAukqw-v-CWhaFF49pXoD7za0NGk91EEEMhmIVjBXIdcVeC1Ba5As0NNZck74tRdxjifQ4";

let lastDevice = null;
export let messagingToken;
export let contacts = {};
export let pendingContacts = [];
let contactsCallback = null;


let os = null;
let uaData = navigator.userAgentData;

if (uaData) {
    uaData.getHighEntropyValues(["platformVersion"]).then(values => os = values.platformVersion);
} else {
    os = "n/a";
}



export function isLoggedIn() {
    return token && sessionInfo && sessionInfo.account_id;
}


/**
 * Make api call
 * @param path relative path
 * @param data request payload
 * @param contentType MIME type
 * @param handler response handler
 */
export function apiCall(path, data, contentType, handler) {
    utils.log('calling api ' + path, data, contentType);

    if (!token) {
        utils.log('Invalid token');
        return;
    }
    const requestOptions = {
        method: data == null ? 'GET' : 'POST',
        headers: { 'Content-Type': contentType || 'application/x-www-form-urlencoded', 'Authorization' : 'Bearer ' + token },
        body: data
    };
    fetch(server + path, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                handler(result)
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                utils.log("error "  + error)
            }
        )
}

/**
 * Make api call with promise
 * @param path relative path
 * @param data request payload
 * @param contentType MIME type
 */
export function apiCall2(path, data, contentType) {
    utils.log('calling api ' + path, data, contentType);

    if (!token) {
        utils.log('Invalid token');
        window.location = "/login";
        return;
    }
    const requestOptions = {
        method: data == null ? 'GET' : 'POST',
        headers: { 'Content-Type': contentType || 'application/x-www-form-urlencoded', 'Authorization' : 'Bearer ' + token },
        body: data
    };
    return fetch(server + path, requestOptions)
        .then(res => res.json())
}


let loginInProgress = false

/**
 * Starts the login procedure
 * @param access_token access token to use for authentication. if undefined will use access token cookie
 * @param userType type of account (facebook, google, etc..) if undefined will use cookie
 * @param callback method to call after successful login
 */

export function start(access_token, userType, callback) {

    //if ((!isChrome && !isFirefox) || mobileCheck()) {
    if (utils.mobileCheck()) {
        utils.showMessage('Browser Not Supported', 'Unfortunately Voicemail Assistant does not support your browser')
        return;
    }

    if (!checkNotificationsPermission()) {
        return;
    }

    initFirebaseMessaging();

    if (loginInProgress) {
        utils.log('Login in progress, ignoring this call');
        return;
    }
    if (isLoggedIn()) {
        utils.log('Already logged in, ignoring this call');
        return;
    }
    utils.log('login start');

    if (!access_token) {
        access_token = utils.readCookie('user_token');
    }

    if ((!access_token) && !utils.readCookie('account_token')) {
        utils.log("login parameters not available");
        window.location.href = "/login";
        return;
    }

    // build login url
    let loginUrl = server + '/api/account/login';

    let data = 'rid=' + new Date().getTime();

    // if (utils.readCookie('account_token')) {
    //     data = data + ("&token=" + encodeURIComponent(utils.readCookie('account_token')));
    // }
    if (userType) {
        data = data + ("&type=" + userType);
    }
    if (access_token) {
        if (userType === FACEBOOK || userType === FIREBASE) {
            data = data + ("&access_token=" + encodeURIComponent(access_token));
        }
        if (userType === GOOGLE) {
            data = data + ("&id_token=" + encodeURIComponent(access_token));
        }
        if (userType === CALL_ASSISTANT) {
            data = data + ("&token=" + encodeURIComponent(access_token));
        }
    }
    let clientName = utils.readCookie('client_name');

    if (!clientName) {
        clientName = 'Web App ' + utils.browserName;
    }

    let id = utils.getClientId();

    data = data + ("&host=false&application=web&client_type=web&device=browser&name=" + encodeURIComponent(clientName) + "&client_id=" + id + "&brand=" + encodeURIComponent(utils.browserName) + "&model=" + encodeURIComponent(utils.OSName) + "&manufacturer=" + encodeURIComponent(window.location.host) + "&protocol=1&version=" + CA_WEB_VERSION + "&version_id=" + versionId);

    if (os) {
        data = data + ("&os_version=" + encodeURIComponent(os));
    }

    if (messagingToken) {
        data = data + ("&registration=" + encodeURIComponent(messagingToken));
    }

    utils.log('login url: ' + loginUrl);

    loginInProgress = true;


    // execute login call

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: data
    };
    fetch(server + "/api/account/login", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                log(result);
                setTimeout(function () {
                }, 1500);
                if (result.status !== "OK") {
                    auth.signOut().then(() => {
                        console.log('Firebase logout after failed login');
                    });
                    // login failed
                    if (result.error_code === "account_not_found") {
                        utils.showMessage('Login Error', 'Account not found! Please Install Call Assistant on your phone first!', 'Install', function() {
                            window.open('https://callassistant.ai');
                        });
                    } else {
                        utils.showMessage('Login Error', 'Something went wrong: ' + JSON.stringify(data));
                    }
                } else {
                    token = result.token;
                    sessionInfo = result.session;
                    utils.createCookie("user_token", access_token);
                    utils.createCookie("account_token", token);
                    initRemoteConfig()
                    subscribeToTopic("REMOTE_CONFIG");
                    if (callback) {
                        callback(result)
                    }
                    //initSession(result.session, token);
                }
                loginInProgress = false;
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                utils.log("error "  + error)
                loginInProgress = false;
                setTimeout(function () {
                    utils.showMessage('Login Error', 'Oops! Something went wrong');
                }, 1500);
            }
        )

}

/**
 * Checks if response is successful
 * @param response json response from API call
 * @returns {boolean} true if successful
 */
export function isOK(response){
    if (response.status !== "OK") {
        if (response.error_code === "invalid_credentials") {
            window.location.hash = "logout";
        }
        if (response.error_code === "token_expired") {
            window.location.hash = "logout";
        }
        if (response.error_code === "permission_denied") {
            window.location.hash = "logout";
        }
        return false;
    }
    return true;
}


// get Current Session

export function getSession(callback) {

    apiCall("/api/account/session", null, null, function (data) {
        if (data.status === "OK") {
            callback(data.session)
        }
    })
}

// get Current Settings

export function getSettings(callback) {

    apiCall("/api/account/settings", null, null, function (data) {
        if (data.status === "OK") {
            callback(data.settings)
        }
    })
}

// Subscribe to topic

function subscribeToTopic(topic) {
    apiCall("/api/fcm/subscribe/" + topic, "", null, function (data) {
    })
}

// Subscribe to topic

function unsubscribeFromTopic(topic) {

    apiCall("/api/fcm/unsubscribe/" + topic, "", null, function (data) {
    })
}



export function hasVoipNumber(){
    if (!sessionInfo) {
        return false;
    }
    for (let i in sessionInfo.verified_numbers) {
        const n = sessionInfo.verified_numbers[i];
        if (n.voip) {
            return true;
        }
    }
    return false;
}

/**
 * Send text reply to caller
 * @param callSid current call sid
 * @param conference conference to send caller after screeing
 * @param text text to say to caller
 * @param language language of text to send (en-US, en-GB, es-ES, pt-PT, etc...)
 */

function replyToCaller (callSid, conference, text, language) {

    let data = 'text=' + encodeURIComponent(text);
    if (language) {
        data += '&lang=' + language;
    }

    apiCall('/api/twilio/screen/' + callSid + "/" + conference, data, null, function (data) {
        if (!isOK(data)) {
            return;
        }
        utils.recordAnalyticsEvent("Web", "account", "reply");
        if (data.status === "OK") {
            //addScreenerBlock('You Said:', text, "images/call_assistant.png")

        } else {
            utils.showMessage('Error', 'Unable to send message:\ncode ' + data.error_code + ', ' + data.error);
        }
    });
}


/**
 * Loads calls from backend from previous call actions
 */

export function setDeviceActive(active) {
    if (!token) {
        console.log("call assistant token not ready");
        return;
    }
    if (!active) {
        try {
            //enablePipMode();
        }catch(e) {
            utils.log("picture in picture error")
        }
    }
    apiCall("/api/account/device/active/" + active, "", null, function (data) {
        utils.log('device active ' + active, data)
        if (!isOK(data)) {
            return;
        }
    });
}

/**
 * Get Subscription
 */

export function getSubscription(callback) {
    apiCall("/api/account/subscription/revenuecat", null, null, function (data) {
        utils.log('subscription', data);
        if (!isOK(data)) {
            return;
        }

        if (callback) {
            callback(data)
        }

        //validateSubscription(data.subscription)
    });
}


/**
 * Returns the last active device
 * @param sessionInfo current session
 * @returns {{host}|any}
 */
export function getLastDevice(){
    if (!sessionInfo) {
        return null;
    }
    if (lastDevice) {
        return lastDevice;
    }
    let devices = sessionInfo.devices;
    let last = 0;
    for (let device of devices) {
        // needs to be host (phone) if tablet or browser client host is set to false
        if (device.host) {
            if (device.last_activity > last) {
                lastDevice = device;
            }
        }
    }
    return lastDevice;
}

function lastDeviceIsIphone(){
    return getLastDevice() && getLastDevice().type === "ios"
}

let verifyRequestId = null

/**
 * Verify Number
 */

export function verifyNumberTwilio(n) {

    verifyRequestId = new Date().getTime();

    let url = "/api/twilio/verify?number=" + encodeURIComponent(n);
    url += "&request_id=" + verifyRequestId;

    return apiCall2(url, null, null);
}

/**
 * Verify Number
 */

export function verifyNumber(n) {

    verifyRequestId = new Date().getTime();

    let url = "/api/account/verify/sms?number=" + encodeURIComponent(utils.getE164Number(n));
    url += "&request_id=" + verifyRequestId;

    return apiCall2(url);
}
/**
 * Verify Number
 */

export function verifyCode(code) {

    let data = "code=" + encodeURIComponent(code);
    data += "&request_id=" + verifyRequestId;

    return apiCall2("/api/account/verify/sms", data);
}
/**
 * Loads numbers from backend
 */

export function testNumber(number) {
    return apiCall2("/api/twilio/voicemail/test", "number=" + encodeURIComponent(number));
}

/**
 * Loads numbers from backend
 */

function loadNumbers() {

    apiCall("/api/account/verified_numbers", null, null, function (data) {

        if (!isOK(data)) {
            return;
        }
        //numbersArray = data.numbers
        sessionInfo.verified_numbers = data.numbers;
    });
}

/**
 * Loads numbers from backend
 */

function loadDevices() {
    apiCall("/api/account/devices", null, null, function (data) {

        if (!isOK(data)) {
            return;
        }
        //devicesArray = data.devices;
        sessionInfo.devices = data.devices;
        //addDevices();
    });
}

/**
 * Loads calls from backend from previous call actions
 */

export function loadCalls(pageSize, callback) {
    apiCall("/api/account/calls?limit=" + pageSize + "&before=" + new Date().getTime(), null, null, function (data) {
        console.log(data);
        if (!isOK(data)) {
            return;
        }

        if (callback) {
            callback(data);
        }

        //callsById = [];
        //callsArray = data.calls;

        //addCalls();
        //loadFavorites();

    });
}

/**
 * Loads calls from backend from previous call actions
 */

export function loadCallsBefore(pageSize, time, callback) {
    apiCall("/api/account/calls?limit=" + pageSize + "&before=" + time, null, null, function (data) {
        console.log(data);
        if (!isOK(data)) {
            return;
        }

        if (callback) {
            callback(data);
        }

        //callsById = [];
        //callsArray = data.calls;

        //addCalls();
        //loadFavorites();

    });
}

/**
 * Loads calls from backend from previous call actions
 */

export function loadCallsAfter(pageSize, time, callback) {
    apiCall("/api/account/calls?limit=" + pageSize + "&since=" + time, null, null, function (data) {
        console.log(data);
        if (!isOK(data)) {
            return;
        }

        if (callback) {
            callback(data);
        }

        //callsById = [];
        //callsArray = data.calls;

        //addCalls();
        //loadFavorites();

    });
}

/**
 * Loads voicemails from backend
 */
export function loadVoicemails(pageSize, callback) {
    apiCall("/api/voicemail/retrieve?limit=" + pageSize + "&before=" + new Date().getTime(), null, null, function (data) {

        console.log(data);

        if (!isOK(data)) {
            return;
        }

        if (callback) {
            callback(data)
        }

        //voicemailsById = [];
        //voicemails = data.voicemails;

    });
}

/**
 * Loads voicemails from backend from before given date
 */

export function loadVoicemailsBefore(pageSize, time, callback) {
    apiCall("/api/voicemail/retrieve?limit=" + pageSize + "&since=" + time, null, null, function (data) {
        console.log(data);
        if (!isOK(data)) {
            return;
        }

        if (callback) {
            callback(data);
        }

        //callsById = [];
        //callsArray = data.calls;

        //addCalls();
        //loadFavorites();

    });
}

/**
 * Loads voicemails from backend after a given date
 */

export function loadVoicemailsAfter(pageSize, time, callback) {
    apiCall("/api/voicemail/retrieve?limit=" + pageSize + "&after=" + time, null, null, function (data) {
        console.log(data);
        if (!isOK(data)) {
            return;
        }

        if (callback) {
            callback(data);
        }

        //callsById = [];
        //callsArray = data.calls;

        //addCalls();
        //loadFavorites();

    });
}


/**
 * mark voicemails as read
 */

export function readVoicemail(id, callback) {
    apiCall("/api/voicemail/read/" + id, "", null, function (data) {
        console.log(data);
        if (!isOK(data)) {
            return;
        }

        if (callback) {
            callback(data);
        }

    });
}


/**
 * Logout
 */
export function logout(callback) {
    let path = "/api/account/logout";
    apiCall(path, "", null, function (data) {
        utils.recordAnalyticsEvent("Web", "account", "logout");
        if (!isOK(data)) {
            return;
        }
        sessionInfo = null;
        token = null;
        if (callback) {
            callback(data);
        }
    });

}


let transcriptions = []


/**
 * Sends an existing call to a conference
 * @param callSid twilio call Sid
 * @param conference conference name
 * @param musicOnly play only music or default hold message
 * @param music play music
 */

function sendToConference(callSid, conference, musicOnly, music, listener) {
    let path = "/api/twilio/conference/" + callSid + "/" + conference;
    let data = "music_only=" + musicOnly + "&music=" + music;
    apiCall(path, data, null, function (data) {
        utils.log('send to conference done', data);
        if (!isOK(data)) {
            if (listener) listener("Unable to connect")
            return;
        }
        utils.recordAnalyticsEvent("Web", "twilio", "send_to_conference");
        utils.log(data);
        if (listener) listener()
    });

}

/**
 * Delete voicemail
 * @param id id of voicemail
 */
export function deleteVoicemail(id, callback) {
    let path = "/api/voicemail/delete/" + id;
    apiCall(path, "", null, function (data) {
        utils.recordAnalyticsEvent("Web", "voicemail", "delete_voicemail");
        if (!isOK(data)) {
            return;
        }

        if (callback) {
            callback(data);
        }
    });

}


/**
 * Delete voicemails
 * @param ids ids of voicemail (comma separated)
 */
export function deleteVoicemails(ids, callback) {
    let path = "/api/voicemail/delete";
    apiCall(path, "voicemail_id=" + ids, null, function (data) {
        utils.recordAnalyticsEvent("Web", "voicemail", "delete_voicemail");
        if (!isOK(data)) {
            return;
        }
        if (callback) {
            callback(data)
        }
    });


}


/**
 * Delete sms
 * @param id id of sms
 */
function deleteSms(id) {
    let path = "/api/account/sms/delete";
    apiCall(path, "sid=" + encodeURIComponent(id), null, function (data) {
        utils.recordAnalyticsEvent("Web", "sms", "delete_sms");
        if (!isOK(data)) {
            return;
        }
        // delete item
        //delete smsById [id];
        // remove from array
        //smsArray = smsArray.filter(function(value, index, arr){ return value.sid !== id;});

    });

}

/**
 * Delete device
 * @param id id of device
 */
function deleteDevice(id) {
    let path = "/api/account/device/reset";
    apiCall(path, "client_id=" + encodeURIComponent(id), null, function (data) {
        utils.recordAnalyticsEvent("Web", "device", "delete_device");
        if (!isOK(data)) {
            return;
        }
        //devicesArray = data.devices;
        sessionInfo.devices = data.devices;
    });

}

/**
 * Delete number
 * @param number
 */
function deleteNumber(number) {
    let path = "/api/account/number/delete";
    apiCall(path, "number=" + encodeURIComponent(number), null, function (data) {
        utils.recordAnalyticsEvent("Web", "number", "delete_number");
        if (!isOK(data)) {
            return;
        }
        loadNumbers();
    });

}

/**
 * Delete voicemail
 * @param id id of voicemail
 */
export function deleteCall(id, callback) {
    let path = "/api/account/call/delete";
    apiCall(path, "call_id=" + id, null, function (data) {
        utils.recordAnalyticsEvent("Web", "call", "delete_call");
        if (!isOK(data)) {
            return;
        }
        if (callback) {
            callback(data)
        }
    });


}

let pendingHangup;

/**
 * Hangup an existing call
 * @param callSid Twilio call Sid
 */

export function hangupCall(callSid, text, lang) {
    if (pendingHangup) {
        return;
    }
    let data = "callSid=" + callSid;
    if (text) {
        data += "&text=" + encodeURIComponent(text)
    }
    if (lang) {
        data += "&language=" + encodeURIComponent(lang)
    }

    pendingHangup = callSid;
    apiCall("/api/twilio/call/hangup", data, null, function (data) {
        if (!isOK(data)) {
            return;
        }
        utils.recordAnalyticsEvent("Web", "twilio", "hangup");
        utils.log('hangup done');
        utils.log(data);
        pendingHangup = null;
    });
}

/**
 * Returns Twilio token used to initialize the Twilio client/device
 */

export function getTwilioToken(room, listener) {
    let url = "/api/twilio/token";
    if (room) url +="?room=" + encodeURIComponent(room);
    apiCall(url, null, null, function (data) {
        utils.recordAnalyticsEvent("Web", "twilio", "token");
        if (!isOK(data)) {
            return;
        }
        utils.log('twilio expires at ' + new Date(data.expires));
        listener(data)
    });
}

let cachedVoices = {}

/**
 * Returns voices associated with a language
 */

export function getVoicesByLang(lang, listener) {
    if (cachedVoices[lang]) {
        utils.log("using cached voices for " + lang);
        listener(cachedVoices[lang])
        return;
    }

    let url = "/api/tts/voices/" + lang;
    apiCall(url, null, null, function (data) {
        utils.recordAnalyticsEvent("Web", "voices", lang);
        if (!isOK(data)) {
            return;
        }
        if (data.voices) {
            cachedVoices[lang] = data.voices;
            listener(data.voices)
        }
    });
}

/**
 * Returns a greeting associated with number or the default greeting
 */

export function getGreeting(number, listener) {
    let url = "/api/account/greeting";
    if (number) url += "?number=" + encodeURIComponent(number);
    apiCall(url, null, null, function (data) {
        utils.recordAnalyticsEvent("Web", "greeting", "get");
        if (!isOK(data)) {
            return;
        }
        console.log("greeting", data.greeting)
        listener(data);
    });
}
export function getGreetingById(id) {
    let url = "/api/voicemail/greeting/" + id;
    return apiCall2(url);
}
//
// export function updateGreeting(number, text, lang, voice, screen) {
//
//     if (!text) {
//         utils.showMessage("Error", "Invalid text for greeting");
//         return;
//     }
//     if (!lang) {
//         utils.showMessage("Error", "Invalid language for greeting");
//         return;
//     }
//
//     let url = '/api/account/greeting';
//
//     let greeting = {}
//
//     greeting.text = text;
//     greeting.language = lang;
//     greeting.voiceId = voice;
//     greeting.url = url;
//
//     let data = "text=" + encodeURIComponent(text) + "&language=" + encodeURIComponent(lang);
//
//     if (number) {
//         data += "&number=" + encodeURIComponent(number);
//     }
//
//     if (voice) {
//         data += "&voice=" + encodeURIComponent(voice);
//     }
//
//     if (screen) {
//         greeting.screen = screen;
//     }
//
//     return apiCall2(url, data);
// }
export function updateGreeting(greeting) {
    if (!greeting.text) {
        utils.showMessage("Error", "Invalid text for greeting");
        return new Promise(() => {});
    }
    if (!greeting.language) {
        utils.showMessage("Error", "Invalid language for greeting");
        return new Promise(() => {});
    }
    let url = '/api/account/greeting';
    let data = JSON.stringify({...greeting, created: null, voiceId: greeting.voice});
    return apiCall2(url, data, 'application/json');
}
export function setDefaultGreeting(greetingId) {

    let url = '/api/account/greeting/default/' + greetingId;
    return apiCall2(url, '');
}
export function deleteGreeting(greetingId) {

    let url = '/api/voicemail/greeting/delete/' + greetingId;
    return apiCall2(url, '');
}

export function generateGreetingVoicemail(lang) {
    let url = '/api/ai/greeting/voicemail';
    return apiCall2(url, JSON.stringify({"language": lang}), 'application/json');
}

export function generateGreetingAssistant(lang) {
    let url = '/api/ai/greeting/assistant';
    return apiCall2(url, JSON.stringify({"language": lang}), 'application/json');
}


/**
 * Register / updates device details
 */
function registerDevice() {

    console.log("register Device", messagingToken)

    let clientName = utils.readCookie('client_name');

    if (!clientName) {
        clientName = 'Web App ' + utils.browserName;
    }

    const id = utils.getClientId();

    let data = ("&host=false&application=web&client_type=web&device=browser&name=" + encodeURIComponent(clientName) + "&client_id=" + id + "&brand=" + encodeURIComponent(utils.browserName) + "&model=" + encodeURIComponent(utils.OSName) + "&manufacturer=" + encodeURIComponent(window.location.host) + "&protocol=1&version=1");
    if (messagingToken) {
        data = data + "&registration=" + encodeURIComponent(messagingToken);
    } else {
        data = data + "&registration=*";
    }

    apiCall('/api/account/device/register', data, null, function (data) {
        if (!isOK(data)) {
            return;
        }
        utils.recordAnalyticsEvent("Web", "devices", "register_device");
        if (data.status === "OK") {
            //showInfo("Device Updated");
        }
    });
}

/**
 * broadcast message all devices to specific device
 * @param json json to send to the device(s)
 * @param clientId  if set sends only to this device otherwise broadcasts message to all devices
 */
export function broadcast(json, clientId, msgId) {

    json.client_id = utils.getClientId();

    let url = '/api/account/broadcast?client_id=' + clientId

    if (msgId) {
        url += '&msg_id=' + msgId;
    }

    apiCall(url, JSON.stringify(json), 'application/json', function (data) {
        if (!isOK(data)) {
            return;
        }
        utils.log(data);
        utils.recordAnalyticsEvent("Web", "account", "broadcast");
        if (data.status === "OK") {
        }
    });
}

/**
 * Retrieve call actions (alternative to accessing Firebase directly)
 * @param callback callback to use after loading actions
 */

function getCallActions(callback) {

    let url = '/api/account/actions'

    apiCall(url, null, 'application/json', function (data) {
        if (!isOK(data)) {
            return;
        }
        utils.log(data);
        utils.recordAnalyticsEvent("Web", "account", "actions");
        if (data.status === "OK") {
            callback(data.actions)
        }
    });
}

/**
 * Retrieve call actions (alternative to accessing Firebase directly)
 * @param sid twilio call sid
 * @param callback callback to use after loading actions
 */

export function getCallBySid(sid, callback) {

    let url = '/api/calls/sid/' + sid;

    apiCall(url, null, 'application/json', function (data) {
        if (!isOK(data)) {
            return;
        }
        utils.log(data);
        utils.recordAnalyticsEvent("Web", "call", "call_sid");
        if (data.status === "OK") {
            callback(data.call)
        }
    });
}

/**
 * Search text in calls
 * @param query term to search
 * @param limit max number of result
 * @param callback callback to use after loading actions
 */

export function searchCalls(query, limit, callback) {

    let url = '/api/calls/search?limit=' + limit + '&query=' + encodeURIComponent(query);

    apiCall(url, null, 'application/json', function (data) {
        if (!isOK(data)) {
            return;
        }
        utils.recordAnalyticsEvent("Web", "calls", "search");
        if (data.status === "OK") {
            callback(data)
        }
    });
}

/**
 * update account settings
 * @param settings settings in json format
 */

export function updateSettings(settings, callback) {

    let url = '/api/account/settings';

    apiCall(url, JSON.stringify(settings), 'application/json', function (data) {
        if (!isOK(data)) {
            return;
        }
        utils.log(data);
        utils.recordAnalyticsEvent("Web", "account", "settings");
        if (data.status === "OK") {
            sessionInfo.settings = data.settings;
            if (callback) {
                callback(data);
            }
        }
    });
}

/**
 * get current call
 * @param callback callback function to run
 */

export function getCurrentCall(callback) {

    let url = '/api/twilio/call/current';

    apiCall(url,null, null, function (data) {
        utils.log(data);
        utils.recordAnalyticsEvent("Web", "call", "current");
        if (callback) {
            callback(data);
        }
    });
}

/**
 * Set call action that will be picked up by the backend the call hangs up
 * when Twilio calls the Webhook
 *
 * @param action call action "screen" / "hold" / "hangup"
 * @param text text to say when twilio receives the call
 * @param lang language of the message ex: pt-PT
 * @param audio audio url to play to caller
 * @param voice voice code
 * @param callSid if set the call will automatically restart
 * @param number original number
 * @param voicemail for "hangup" actions, send call to voicemail before hangup
 * @param record record call
 * @param autoPilot auto pilot mode (let assistant interact with caller when screening calls)
 * @param stream stream audio when screening calls
 * @param transcribe transcribe call
 * @param callback function to call
 */

export function setCallAction({action, text, lang, audio, voice, callSid, number, voicemail, record, autoPilot, stream, transcribe}, callback) {

    const id = utils.guid();

    let data = "&counter=" + new Date().getTime() + "&action=" + action;
    if (lang) data += "&language=" + encodeURIComponent(lang);
    if (voice) data += "&voice_id=" + encodeURIComponent(voice);
    if (callSid) data += "&call_id=" + encodeURIComponent(callSid);
    if (number) data += "&number=" + encodeURIComponent(number);
    if (record || sessionInfo.settings.record_calls === "true") data += "&record=true";
    if (autoPilot) data += "&auto_pilot=" + encodeURIComponent(autoPilot);
    if (stream) data += "&stream=" + encodeURIComponent(stream);
    if (transcribe) data += "&transcribe=" + encodeURIComponent(transcribe);
    data += "&voicemail=" + encodeURIComponent(voicemail === true);
    if (sessionInfo.settings.streams === "true") {
        data += "&stream=true";
    }
    if (sessionInfo.settings.auto_pilot === "true") {
        data += "&auto_pilot=true";
    }
    if (text) {
        data += ("&text=" + encodeURIComponent(text));
    }
    if (audio) {
        data += ("&url=" + encodeURIComponent(audio));
    }

    apiCall('/api/twilio/call/action/' + id, data, null, function (data) {
        if (!isOK(data)) {
            return;
        }
        utils.recordAnalyticsEvent("Web", "account", "call_action");
        if (data.status === "OK") {
            callback(data)
        }
    });
}

export function getTwilioCall(callSid, handler){
    apiCall("/api/twilio/call/" + callSid, null, null, function (data) {
        if (data.status === 'OK') {
            utils.log("Twilio call", data.call);
            handler(data.call)
        } else {
            handler();
        }
    })

}

/**
 * get current call
 * @param callback callback function to run
 */

export function getGreetings(callback) {

    let url = '/api/voicemail/greetings';

    return apiCall2(url);
}



export function enableAudioStream(callSid, enable, handler){
    apiCall("/api/twilio/stream/" + callSid, "enable=" + (enable === true), null, function (data) {
        utils.log("Twilio stream enable " + enable, data);
        if (data.status === 'OK') {
            handler(data);
        } else {
            handler();
        }
    })
}

function checkActiveCall(){
    getTwilioCall("active", function (call) {
        apiCall("/api/twilio/call/active", null, null, function (data) {
            if (call) {
                utils.log("Active call", call);
                apiCall("/api/twilio/restart/" + (call.parentCallSid || call.callSid), "", null, function (data) {
                    utils.log("restarting call", call);
                })
            }
        })
    })

}



export function checkNotificationsPermission(){
    if(!window.Notification){
        showMessage("Error", "This browser is not supported by Voicemail Assistant.")
    }else{
        if (window.safari) {
            // requestSafariPermission()
            // return;
        }
        if (Notification.permission === "granted") {
            return true;
        }
        showMessage("Notifications", "Please allow Voicemail Assistant to show notifications", "Allow", function () {

            Notification.requestPermission().then(function (permission) {
                console.log(permission);
                if (permission === 'denied') {
                    showMessage("Error", "Permission denied.")
                } else if (permission === 'granted') {
                    showMessage("Success", "Voicemail Assistant can now show notifications")
                    if (!window.safari) {
                        initFirebaseMessaging();
                    }
                }
            })
        });
        return false;
    }

}

/**
 * Initialize firebase
 */
export function initFirebaseMessaging() {
    try {
        getToken(messaging, {vapidKey: publicVapidKey}).then((currentToken) => {
            if (currentToken) {
                console.debug('Token retrieved ' + currentToken);
                messagingToken = currentToken;
                if (isLoggedIn()) {
                    registerDevice();
                    subscribeToTopic("REMOTE_CONFIG");
                } else {
                    //showQrCode();
                }
            }
        }).catch((err) => {
            console.debug('An error occurred while retrieving token. ', err);
        });

        // messaging.onMessage((payload) => {
        //     console.debug('Message received. ', payload);
        //
        //     let json = payload.data.message;
        //
        //     let message = JSON.parse(json);
        //     handleMessage(message)
        //
        // });
    } catch (e) {
        console.error(e);
    }
}

let lastChunk;

/**
 * Handle incoming FCM message payload
 * @param msg fcm message payload
 */
export function handleMessage(msg) {
    console.log("handle message", msg);
    if (!msg) {
        return;
    }

    if (msg.action === "token") {
        token = msg.token;
        start(token, CALL_ASSISTANT);
        return;
    }

    if (msg.action === "transcription_update") {
        if (msg.status === "done") {
            //showNotification2("Transcription", "Transcription completed")
            utils.popupNotification("Transcription", "Transcription completed", "transcription")
        }
        if (msg.status === "error") {
            //showNotification2("Transcription", "There was an error with the transcription")
            utils.popupNotification("Transcription", "There was an error with the transcription", "transcription")
        }
        if (msg.status === "started") {
            //showNotification2("Transcription", "Transcription started")
            utils.popupNotification("Transcription", "Transcription started", "transcription")
        }
        return;
    }

    if (msg.action === "voicemail_test") {
        if (msg.success) {
            utils.showMessage("Test Voicemail", "Test call was successful")
            //showNotification2('Test Voicemail', 'Test call was successful')
        } else {
            utils.showMessage("Test Voicemail", "Test call was not successful, please try again")
            //showNotification2('Test Voicemail', 'Test call was not successful')
        }
        return;
    }

    if (msg.action === "call_recording") {

        // callsArray.forEach(function(call) {
        //     if (call.call_id === msg.call_sid) {
        //         call.recording = msg.url;
        //     }
        // });
        // addCalls()
    }


    if (!isLoggedIn()) {
        return;
    }
    if (msg.action === "contact_image") {
        //handleContactImage(msg);
        return;
    }

    if (msg.action === "quick_replies_result") {
        // let replies = msg.replies;
        // addQuickReplies(replies);
        return;
    }

    if (msg.action === "reset") {
        if (utils.getClientId() === msg.client_id) {
            // signOut();
            // window.location.hash = "login";
        }
        return;
    }
    if (msg.action === "remote_config") {
        initRemoteConfig();
        return;
    }
    if (msg.action === "stream_start") { // audio stream start
        //initAudioStream()
        return;
    }
    if (msg.action === "stream_media") { // audio data received base64 encoded

        // if (!currentScreenerCallId) {
        //     hangupCall(msg.call_sid);
        //     return;
        // }
        //
        // // base64 encoded data
        // let payload = msg.payload;
        // // message chunk
        // let chunk = msg.chunk;
        // if (chunk <= lastChunk) {
        //     // skip old chunk
        //     return;
        // }
        // lastChunk = chunk;
        // let track = msg.track; // just inbound (for now)
        // // decode data
        // let data = _base64ToArrayBuffer(payload)
        // // play data
        // player.feed(data);
        return;
    }
    //
    // if (msg.action === "video_call_incoming") {
    //     if (getRemoteConfigBoolean("video")) {
    //         handleIncomingVideoCall(msg);
    //     }
    // }
    // if (msg.action === "video_call_answer") {
    //     if (getRemoteConfigBoolean("video")) {
    //         handleAnswerVideoCall(msg);
    //     }
    // }
    // if (msg.action === "video_call_reject") {
    //     if (getRemoteConfigBoolean("video")) {
    //         handleVideoCallRejected(msg);
    //     }
    // }
    // if (msg.action === "video_call_hangup") {
    //     if (getRemoteConfigBoolean("video")) {
    //         handleVideoCallHangup(msg);
    //     }
    // }
    // if (msg.action === "video_call_ringing") {
    //     if (getRemoteConfigBoolean("video")) {
    //         $("#video_status").text("Ringing...");
    //     }
    // }
    // if (msg.action === "sms") {
    //     handleSms(msg);
    // }

    // if (msg.action === "conference") { // conference events
    //     if (msg.event === "participant-join") {
    //         conferenceParticipants++;
    //         console.log("participant joined, total " + conferenceParticipants)
    //     }
    //     if (msg.event === "participant-left") {
    //         conferenceParticipants--;
    //         console.log("participant left, total " + conferenceParticipants)
    //     }
    //     if (conferenceParticipants > 1) {
    //         // 2 participants call is on
    //         $("#call_status").text("Voicemail Resumed");
    //         $("#incoming_call_menu_item").show();
    //         $("#call_screen").fadeIn();
    //         $("#resume_call").hide();
    //         $("#hold").show();
    //         location.hash = "incoming";
    //     } else {
    //         $("#incoming_call_menu_item").hide();
    //     }
    //     return;
    // }
    //
    // if (msg.action === "call_action") { // call action
    //     if (msg.call_action === "screen") {
    //         // start screener session
    //         setTimeout( function() {
    //             startScreener(msg.call_id, msg.from, msg.url, msg.text, msg.lang);
    //             showNotification({
    //                 title: "Voicemail Screening",
    //                 timeout: 8000,
    //                 body: "Voicemail is being screened: " + msg.from + "<br/>" + msg.text
    //             });
    //         }, 1000);
    //
    //     }
    //     if (msg.call_action === "hold") {
    //         // initialized UI
    //         conferenceParticipants = 0;
    //         $("#call_status").text("Caller on Hold");
    //         $("#incoming_call_menu_item").show();
    //         $("#call_screen").fadeIn();
    //         $("#resume_call").fadeIn();
    //         $("#hold").show();
    //
    //         currentCallId = msg.call_id;
    //
    //         // start call on hold
    //         if (autoAnswerHold) {
    //             connectToCall(msg.call_id, getCurrentConferenceCall(msg.call_id));
    //         } else {
    //             showNotification( {
    //                 title: "On Hold",
    //                 timeout: 8000,
    //                 body: "Voicemail is now on hold: " + msg.from + "<br/>" + msg.text
    //             });
    //             startCallTimer();
    //         }
    //         autoAnswerHold = false;
    //     }
    //     if (msg.call_action === "conference") {
    //         // initialized UI
    //         conferenceParticipants = 0;
    //         $("#call_status").text("Caller joined conference");
    //         $("#incoming_call_menu_item").show();
    //         $("#call_screen").fadeIn();
    //         $("#resume_call").fadeIn();
    //         $("#hold").show();
    //
    //         currentCallId = msg.call_id;
    //
    //         // start call on hold
    //         if (autoAnswerHold) {
    //             connectToCall(msg.call_id, getCurrentConferenceCall(currentCallId));
    //         } else {
    //             showNotification( {
    //                 title: "Caller joined call",
    //                 timeout: 8000,
    //                 body: "Voicemail is now on the conference call"
    //             });
    //             startCallTimer();
    //         }
    //         autoAnswerHold = false;
    //     }
    //     return;
    // }
    // if (msg.action === "record_voicemail") {
    //     currentCallId = msg.call_id;
    //     showIncomingCall(null, msg.number);
    //     return;
    // }
    // if (msg.action === "screener") {
    //     // screener transcription
    //     handleScreener(msg);
    //     return;
    // }
    // if (msg.action === "screener_repeat") {
    //     // screener transcription
    //     addScreenerBlock('Assistant Said:', msg.text || "Can you repeat that");
    //     return;
    // }
    // if (msg.action === "screener_reply") {
    //     // screener transcription
    //     addScreenerBlock('Assistant Said:', msg.text);
    //     showNotification( {
    //         title: "Assistant Said",
    //         body: msg.text
    //     });
    //     return;
    // }
    // if (msg.action === "twilio_hangup") { // twilio call disconnected
    //     pendingHangup = false;
    //     $("#call_status").text("call disconnected");
    //     $("#incoming_call_menu_item").hide();
    //     if (currentScreenerCallId && currentScreenerCallId === msg.call_id) { // check current screener
    //         // add message to screener
    //         addScreenerBlock('Caller Hangup', "Unable to find out who the caller is", "images/call_assistant.png");
    //         // call has hung up
    //         $("#incoming_call_menu_item").hide();
    //         currentScreenerCallId = null;
    //         currentCallClientId = null;
    //     }
    //     if (currentCallId == msg.call_id) {
    //         currentCallId = null;
    //     }
    //     // update UI
    //     $("#screener_reply").fadeOut();
    //     $("#screener_buttons").hide();
    //     $("#call_buttons").hide();
    //     $("#incoming_call").fadeOut();
    //     $("#hold").hide();
    //     $("#resume_call").hide();
    //     window.location.hash = "calls";
    //     showNotification( {
    //         title: "Voicemail",
    //         body: "Voicemail disconnected"
    //     });
    //     return;
    // }
    if (msg.action === "broadcast") {
        if (msg.broadcast === "contacts") { // received contacts
            handleContacts(msg);
            return;
        }
        if (msg.broadcast === "contact_image") { // received contact image
            handleContactImage(msg);
            return;
        }
        if (msg.broadcast === "status") { // received contact image
            //handleStatus(msg);
            return;
        }
        // if (msg.broadcast === "quick_replies_result") {
        //     let replies = msg.replies;
        //     addQuickReplies(replies);
        //     return;
        // }

        // if (msg.broadcast === "call") { // received call event from device
        //     if (msg.state === "ringing") { // call is ringing on device
        //         if (remoteCallTimeout) {
        //             clearTimeout(   remoteCallTimeout);
        //         }
        //         setTimeout(function () {
        //             handleRemoteCallRinging(msg);
        //         }, 150);
        //     }
        //     if (msg.state === "idle") { // phone on hook
        //         // update UI
        //         setTimeout( function () {
        //             currentCallClientId = null
        //             $("#call_status").text("");
        //             $("#incoming_call_menu_item").hide();
        //             $("#incoming_call").fadeOut();
        //             hideIncomingCallWheel();
        //         }, 200);
        //     }
        // }
        return;
    }
}

export function findContact(number) {
    if (!contacts || Object.keys(contacts).length === 0) {
        let loadedContacts = window.localStorage.getItem('contacts');
        if (loadedContacts) {
            contacts = JSON.parse(loadedContacts);
        } else {
            contacts = {}
        }
    }
    return contacts[number];
}


function handleContactImage(message){
    let url = message.url;
    if (!url) {
        let d = message.data;
        url = "data:image/png;base64," + d
    }
    let c = findContact(message.number);
    if (c) saveContactImage(c, url);
}

function getContactImageKey(contact){
    if (!contact) {
        return null;
    }
    return contact.id + "_img_url";
}

function getContactImage(contact){
    try {
        const img = window.localStorage.getItem(getContactImageKey(contact));
        if (img) {
            return img;
        }
    }catch (e) {
        log(e.message);
        log(e);
    }
}

export async function saveButtons(buttons){
    let actionsRef = collection(db, "account/" + sessionInfo.account_id + "/actions");
    let q = query(actionsRef)
    let querySnapshot = await getDocs(q);
    querySnapshot.forEach(function (doc) {
        deleteDoc(doc.ref)
    });
    for (let i in buttons) {
        await addDoc(actionsRef, buttons[i]);
    }
    showMessage('Buttons', 'Buttons save successfully')
}

const buttonColors = ["33b5e5", "669900", "ffbb33", "0099cc", "ff8800", "33b5e5"];

function getButtonColor(pos) {
    return buttonColors[pos % buttonColors.length];
}

/**
 * Default incoming call buttons
 * @returns {({iconId: number, mode: number, pos: number, language: string, text: string, title: string}|{iconId: number, mode: number, pos: number, language: string, text: string, title: string}|{iconId: number, mode: number, pos: number, language: string, text: string, title: string}|{iconId: number, mode: number, pos: number, language: string, text: string, title: string}|{iconId: number, mode: number, pos: number, language: string, text: string, title: string})[]}
 */
function loadDefaultButtons(){
    const name = sessionInfo.settings.name || sessionInfo.name
    return [
        {
            pos: 0,
            iconId: 957,
            color: getButtonColor(0),
            mode: 0,
            language: "en-US",
            text: "Hi, I'm " + name + "'s Voicemail Assistant. Please state your name and why you are calling and I will try to get " + name + " on the line.",
            title: "Screener"
        },
        {
            pos: 1,
            color: getButtonColor(1),
            iconId: 23,
            mode: 1,
            language: "en-US",
            text: "Please Hold On, " + name + " will be right with you",
            title: "Hold"
        },
        {
            pos: 2,
            color: getButtonColor(2),
            iconId: 195,
            mode: 2,
            language: "en-US",
            title: "Voicemail Back",
            text: "Hi! " + name +  " wanted me to tell you he saw your call and set a reminder to call you back. Feel free to leave a message."
        },
        {
            pos: 3,
            color: getButtonColor(3),
            iconId: 54,
            mode: 2,
            language: "en-US",
            title: "Text Me",
            text: "Hi! " + name +  " would like you to text him. However, feel free to leave a message"
        },
        {
            pos: 4,
            color: getButtonColor(4),
            iconId: 257,
            mode: 2,
            language: "en-US",
            text: "Hi! " + name + " is in a meeting. I will remind him to call you back. Feel free to leave a message",
            title: "Meeting"
        }
    ]
}


export async function loadButtons(callback) {
    let actionsRef = collection(db, "account/" + sessionInfo.account_id + "/actions");
    let q = query(actionsRef)
    let querySnapshot = await getDocs(q);
    let actions = [];
    querySnapshot.forEach(function (doc) {
        let data = doc.data();
        actions.push(data);
    });

    if (actions.length <= 0) {
        actions = loadDefaultButtons();
    }

    if (callback) callback(actions.sort((a, b) => a.pos - b.pos))

    return actions.sort((a, b) => a.pos - b.pos);
}


function saveContactImage(contact, url){
    if (contact) {
        window.localStorage.setItem(getContactImageKey(contact), url);
    }
}

export function requestContacts(callback) {

    if (getLastDevice()) {
        // showNotification({
        //     title: "Contacts",
        //     body: "Loading contacts, please wait..."
        // });

        contactsCallback = callback

        broadcast({
            'action': 'contacts',
            'title': 'Contacts',
            'body': 'Contacts access requested'
        }, lastDevice.id, lastDevice.id);
    }
}

/**
 * Handle Contacts message
 * @param message json message
 */

function handleContacts (message){
    const current = pendingContacts.length;
    addToContacts(message.contacts);
    const after = pendingContacts.length;
    if (current === after) {
        return;
    }

    if (pendingContacts.length >= message.total) {
        contacts = {}
        for (let i = 0; i < pendingContacts.length; i++) {
            const c = pendingContacts[i];
            const numbers = c.numbers;
            for (let j = 0; j < numbers.length; j++) {
                const number = numbers[j];
                contacts[number] = c;
            }
        }
        window.localStorage.setItem('contacts_list', JSON.stringify(pendingContacts));
        window.localStorage.setItem('contacts', JSON.stringify(contacts));
        if (contactsCallback) {
            contactsCallback(pendingContacts)
            contactsCallback = null
        }
        // showNotification( {
        //     title: "Contacts",
        //     body: "Contacts loaded successfully"
        // });
    }

}

let timeoutContacts;

function addToContacts(newContacts){
    log('new contacts: ' + newContacts.length);
    clearTimeout(timeoutContacts);
    for (let i = 0; i < newContacts.length; i++) {
        const c = newContacts[i];
        const numbers = c.numbers;
        for (let j = 0; j < numbers.length; j++) {
            const number = numbers[j];
            contacts[number] = c;
        }
        pendingContacts.push(c);
    }
    timeoutContacts = setTimeout(function(){
        contacts = {}
        for (let i = 0; i < pendingContacts.length; i++) {
            const c = pendingContacts[i];
            const numbers = c.numbers;
            for (let j = 0; j < numbers.length; j++) {
                const number = numbers[j];
                contacts[number] = c;
            }
        }
        if (contactsCallback) {
            contactsCallback(pendingContacts);
        }
    }, 1000);
}



/**
 * initialize remote config
 */
function initRemoteConfig() {
    const settings = rc.settings;
    const defaultMinimumFetchIntervalMillis = settings.minimumFetchIntervalMillis;
    settings.minimumFetchIntervalMillis = 0;
    rc.settings = settings;
    fetchAndActivate(rc)
        .then(() => {
            log("remote config loaded");
            settings.minimumFetchIntervalMillis = defaultMinimumFetchIntervalMillis;
            rc.settings = settings;
        })
        .catch((err) => {
            log("remote config error", err);
        });

}