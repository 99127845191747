import React from "react";
import ReactLoading from "react-loading";

function Loading() {
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: "absolute", left: 0, top: 0, bottom:0, right:0}}>
            <ReactLoading type="spinningBubbles" className="loading"/>
        </div>
    );
}

export default Loading;
