import React, {useEffect, useRef, useState} from "react";
import "./Call.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {IconButton, TextField} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {getDevice, getStream} from "../../features/callSlice";
import {useSelector} from "react-redux";
import CallMessage from "./CallMessage";
import {broadcast, findContact, sessionInfo, setCallAction} from "../../app/api";
import * as libphonenumber from "libphonenumber-js";
import {CallEnd, Send, VolumeMute, VolumeUp} from "@material-ui/icons";
import PhoneIcon from "@material-ui/icons/Phone";
import Button from "@mui/material/Button";
import * as api from "../../app/api";
import {ButtonsMenu} from "../Settings/Buttons";
import {Divider} from "@mui/material";
import Wave from 'wave-visualizer';
import {extractNumber} from "../../app/utils";

function FullIncomingCall({incomingCall, setIncomingCall}) {
    const history = useHistory();
    const device = useSelector(getDevice);
    const textRef = useRef();
    const [actions, setActions] = useState([]);
    const stream = useSelector(getStream);
    let [wave] = useState(new Wave());

    const title = () => {

        let name = findContact(incomingCall?.number)?.name

        let t = incomingCall?.number;
        if (t) {
            try {
                let n = libphonenumber.parse(incomingCall?.number)
                t = libphonenumber.format(n, n.country === sessionInfo?.country ? 'NATIONAL' : 'INTERNATIONAL')
            } catch (e) {
                console.log(e)
            }
        } else {
            t = "Unknown";
        }

        if (name) {
            return name + " (" + t + ")";
        }

        return t
    }
    const conferenceName = () => sessionInfo?.account_id?.hashCode() + "_" + incomingCall?.number?.hashCode() + "_conf";

    const toggleAudioStream = () => {

        if (incomingCall.callSid) {
            const enable = !(incomingCall.stream === true);
            api.enableAudioStream(incomingCall.callSid, enable, () => {
                setIncomingCall({...incomingCall, stream: enable})
            })
        }

    }
    const answer = () => {
        console.log("incoming call answer")
        // check for active incoming call
        if (incomingCall) {
            if (!incomingCall.incoming) { // check for existing twilio connections
                // no active twilio call.
                // must be a call from a device
                let conf = conferenceName();
                console.log("incoming call setting conference action");
                let callSid = incomingCall.callSid;
                // STEP 1: set the call action
                // the action consists in moving the incoming call to conference line
                setCallAction({action: "conference", callSid: callSid, text: conf, number: incomingCall.number}, () => {
                    // check existing twilio call
                    if (!callSid) {
                        // no active twilio call, must be a call from a device
                        // lets ask the device to hangup the call. This is forward the call to Call Assistant
                        console.log("incoming call disconnect original call")
                        broadcast({'action': 'hangup'}, incomingCall.clientId, incomingCall.clientId);
                    }
                    // STEP 2: Connect to the conference call mentioned on Step 1
                    // these parameters will make the call connect the conference call
                    let params = {
                        conference: conf,
                        account: sessionInfo.account_id,
                    };
                    console.log("incoming call connect to conference", params);
                    device.connect(params);
                    setIncomingCall({...incomingCall, state: "connected"})
                });
            } else {
                console.log("incoming call accepting incoming call")
                incomingCall.incoming.accept();
                setIncomingCall({...incomingCall, state: "connected"})
            }
        }
    }

    /**
     * Hangup current call
     */
    const hangup = () => {
        // check twilio call
        if (incomingCall.callSid) {
            // send action to hangup call
            api.setCallAction({
                action: "hangup",
                callSid: incomingCall.callSid,
                text: "",
                voicemail: false,
                number: incomingCall.number,
                lang: incomingCall.language,
                voice: incomingCall.voice,
            }, () => {
            })
        } else if (incomingCall.incoming) {
            // reject incoming call
            incomingCall.incoming.reject();
            setIncomingCall(null);
        } else if (incomingCall.connection) {
            // disconnect all current calls
            device.disconnectAll();
        } else if (incomingCall.clientId) {
            // must be a call from another device
            // broadcast hangup message to device
            broadcast({'action': 'hangup'}, incomingCall.clientId, incomingCall.clientId);
        }
    }

    /**
     * Send message to caller
     */
    const sendMessage = () => {
        // get text from inout field
        let text = textRef.current.value;

        // set action to play messages to caller
        api.setCallAction({
            action: "screen",
            callSid: incomingCall.callSid,
            text: text,
            number: incomingCall.number,
            lang: incomingCall.language,
            voice: incomingCall.voice,
        }, () => {
            textRef.current.value = "";
            textRef.current.focus();
        })
    }

    const handleSendMessage = () => {
        sendMessage();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendMessage(event.target.value);
        }
    };

    /**
     * Handle action from incoming call buttons
     * @param action action to execute
     */
    const handleAction = (action) => {
        let actionName = "screen";
        let voicemail;
        if (action.mode === 0) {
            actionName = "screen";
        }
        if (action.mode === 1) {
            actionName = "hold";
        }
        if (action.mode === 2) {
            actionName = "hangup";
            voicemail = true;
        }
        if (action.mode === 3) {
            actionName = "hangup";
        }
        // send call action
        api.setCallAction({
            "action": actionName,
            "text": action.text,
            "lang": action.language,
            "voice": action.voice,
            "voicemail": voicemail,
            "number": incomingCall.number,
            "callSid": incomingCall.callSid,
        }, (result) => {
            console.log("set all action", result);
            setIncomingCall({...incomingCall, state: "connecting"});
            if (!incomingCall.callSid) {
                // not a twilio call
                // probably a call from an Android device, we should hang up the call
                // on the device and forward the call to Call Assistant
                hangup();
            }
            history.push("/incoming");
        });
    }

    const callState = () => {
        let state = incomingCall?.state;
        if (state) {
            switch (state) {
                case "ringing":
                    if (incomingCall.clientId) {
                        return "Ringing on device";
                    } else {
                        return "Ringing";
                    }
                case "screening":
                    return "Screening call";
                case "connecting":
                    return "Connecting...";
                case "connected":
                    return "Connected";
                case "disconnected":
                    return "Disconnected";
                default:
                    return "Unknown"
            }
        }
    }


    useEffect(() => {
        // check and load incoming call actions
        if (!actions || actions.length === 0) {
            // load call actions
            api.loadButtons().then(actions => {
                setActions(actions);
            });
        }
        if (!incomingCall) {
            api.getCurrentCall((data) => {
                console.log('current call', data);
                if (data.call) {
                    let sid = data.call.sid;
                    let from = extractNumber(data.call.from);
                    setIncomingCall({callSid: sid, number: from, state: "ringing"})
                } else {
                    history.goBack();
                }
            })
        }
    }, []);

    useEffect(() => {
        if (incomingCall && stream) {
            wave.fromStream(stream, 'audio_output', {
                colors: ['red', 'white', 'blue'],
            });
        }
        if (!incomingCall) {
            setTimeout(() => history.push('/calls'), 1000);
        }
    }, [incomingCall]);

    if (!incomingCall) {
        return <></>;
    }

    return (
        <div className="full-incoming-call">
            <div className="call-tools">
                <div className="call-tools-left">
                    <IconButton onClick={() => history.goBack()}>
                        <ArrowBackIcon/>
                    </IconButton>
                </div>
                <div className="call-tools-right">
                    {incomingCall?.state === "screening" && <div>
                        {incomingCall?.stream === true &&<canvas id="audio_output" height="30" width="100"></canvas>}
                        {incomingCall?.stream === true &&  <Button title="Disable Audio Stream" onClick={() => toggleAudioStream()}>
                            <VolumeMute/> Disable Audio Stream
                        </Button>}
                        {!incomingCall?.stream && <Button title="Enable Audio Stream" onClick={() => toggleAudioStream()}>
                            <VolumeUp/> Enable Audio Stream
                        </Button>}
                    </div>
                    }
                    {incomingCall?.state !== "connected" &&
                        <Button title="Answer" onClick={() => answer()}>
                            <PhoneIcon/> Answer
                        </Button>
                    }
                    <Button title="hangup" onClick={() => hangup()}>
                        <CallEnd/> Hangup
                    </Button>
                </div>
            </div>
            <div className="call-body">
                <div className="call-bodyHeader">
                    <div className="call-subject">
                        <IconButton>
                            <PhoneIcon/>
                        </IconButton>
                        <div className="call-subject-content">
                            <h2>{title()}</h2>
                            <span className="call-time">Incoming Call ({callState()})</span>
                        </div>
                    </div>
                    <div className="call-tools-right">
                    </div>
                </div>
                <Divider/>
                {incomingCall?.state === "ringing" && actions && actions.length > 0 &&
                    <div className="full-incoming-call-buttons">
                        <ButtonsMenu buttons={actions} clickHandler={handleAction}/>
                        <br/>
                        <br/>
                        <div className="full-incoming-call-bottom-buttons">
                            <Button onClick={answer}><PhoneIcon/>&nbsp;Answer</Button>
                            <Button onClick={hangup}><CallEnd/>&nbsp;Hangup</Button>
                        </div>
                    </div>
                }
                <div className="call-content">

                    {incomingCall?.state === "screening" && (incomingCall?.messages == null || incomingCall?.messages?.length === 0) &&
                        <span className="contactList-no-contacts">No Messages</span>}
                    {
                        incomingCall?.messages?.length > 0 && incomingCall?.messages?.map((message) => (
                            <CallMessage key={Math.random()} message={message}/>
                        ))}
                </div>
                {incomingCall?.state === "screening" && <div className="screening-text-container">
                    <TextField
                        inputRef={textRef}
                        onKeyDown={handleKeyDown}
                        className="screening-text"
                        placeholder="Type your message"
                        label="Message"
                        multiline
                        minRows={2}/>
                    <IconButton title="Send Message" onClick={handleSendMessage} className="screening-send">
                        <Send/>
                    </IconButton>
                </div>}
            </div>
        </div>
    );
}

export default FullIncomingCall;
