import {Checkbox, IconButton} from "@material-ui/core";
import React from "react";
import "./VoicemailRow.css";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    setVoicemails,
    selectVoicemail,
    getSelectedVoicemails,
    removeSelectedVoicemail,
    addSelectedVoicemail, getVoicemails
} from "../../features/voicemailSlice";
import {sessionInfo, findContact, readVoicemail} from "../../app/api";
import * as libphonenumber from "libphonenumber-js";
import {Voicemail} from "@mui/icons-material";

function VoicemailRow({id, sid, number, date, duration, transcription, recording, read}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedVoicemails = useSelector(getSelectedVoicemails)
    const voicemails = useSelector(getVoicemails)

    const formattedDate = () => new Date(date).toDateString()

    const title = () => {

        let name = findContact(number)?.name

        let t = number;
        try {
            let n = libphonenumber.parse(number)
            t = libphonenumber.format(n, n.country === sessionInfo?.country ? 'NATIONAL' : 'INTERNATIONAL')
        }catch (e) {
            console.log(e)
        }

        if (!t) {
            t = number;
        }

        if (name) {
            return name + " (" + t + ")";
        }

        return t
    }
    const openVoicemail = () => {

        if (!read) {
            read = true;
            readVoicemail(id, function (data) {
                console.log("voicemail read result", data);
                const updatedVoicemails = voicemails.map(v => v.id === id ? { ...v, read: true } : v);
                dispatch(setVoicemails(updatedVoicemails));
            });
        }

        dispatch(
            selectVoicemail({
                id,
                sid,
                number,
                date,
                duration,
                transcription,
                recording,
                read
            })
        );
        history.push("/voicemail");
    };
    const durationString = () => {
        let seconds = duration % 60;
        if (duration > 60) {
            return Math.floor(duration / 60) + "m " + seconds + "s"
        } else {
            return seconds + "s"
        }
    }
    const isSelected = () => {
        let found = selectedVoicemails?.find(call => call === id);
        return found && found.length > 0;
    }

    const handleVoicemailChecked = (event) => {
        let checked = event.target.checked;

        if (checked) {
            dispatch(addSelectedVoicemail({id: id}));
        } else {
            dispatch(removeSelectedVoicemail({id: id}));
        }

    }

    return (
        <div className="itemRow">
            <div className="itemRow-options">
                { isSelected() && <Checkbox onChange={handleVoicemailChecked} defaultChecked/>}
                { !isSelected() && <Checkbox onChange={handleVoicemailChecked}/>}
                <IconButton onClick={openVoicemail}className={!read ? "voicemailRow-unread" : ""}>
                    <Voicemail/>
                </IconButton>
            </div>
            <h3 onClick={openVoicemail} className="voicemailRow-title">{title()}</h3>
            <div onClick={openVoicemail} className="voicemailRow-message">
                <h4>
                    <span className="voicemailRow-description">{transcription}</span>
                </h4>
            </div>
            <p className="voicemailRow-time">{durationString()}</p>
            <p className="voicemailRow-time">{formattedDate()}</p>
        </div>
    );
}

export default VoicemailRow;
