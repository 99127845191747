import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {showMessage} from "../../app/utils";
import {initFirebaseMessaging} from "../../app/api";

function NotificationDialog({desktopNotification, setDesktopNotification}) {

    const handleDesktopNotificationClose = () => {
        setDesktopNotification(false);
    };

    const handleDesktopNotification = () => {
        Notification.requestPermission().then(function (permission) {
            console.log(permission);
            if (permission === 'denied') {
                showMessage("Error", "Permission denied.")
            } else if (permission === 'granted') {
                showMessage("Success", "Voicemail Assistant can now show notifications")
                if (!window.safari) {
                    initFirebaseMessaging();
                }
            }
        })
        setDesktopNotification(false);
    };

    return (
      <Dialog
          open={desktopNotification}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
              {"Desktop Notifications?"}
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  Allow Desktop Notifications
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleDesktopNotificationClose}>Cancel</Button>
              <Button onClick={handleDesktopNotification}>
                  Allow
              </Button>
          </DialogActions>
      </Dialog>
  );
}

export default NotificationDialog;
