import {createSlice} from "@reduxjs/toolkit";

export const buttonSlice = createSlice({
    name: "button",
    initialState: {
        button: null,
        buttons: null,
        voices: [],
        icons: [],
    },
    reducers: {
        setButton: (state, action) => {
            state.button = action.payload;
        },
        setButtons: (state, action) => {
            state.buttons = action.payload;
        },
        setVoices: (state, action) => {
            state.voices = action.payload;
        },
        setIcons: (state, action) => {
            state.icons = action.payload;
        },
    },
});

export const {setButton, setButtons, setVoices, setIcons} = buttonSlice.actions;
export const getButton = (state) => state.button.button;
export const getButtons = (state) => state.button.buttons;
export const getVoices = (state) => state.button.voices;
export const getIcons = (state) => state.button.icons;
export default buttonSlice.reducer;
