import { configureStore } from "@reduxjs/toolkit";
import mailReducer from "../features/mailSlice";
import userReducer from "../features/userSlice";
import callReducer from "../features/callSlice";
import voicemailReducer from "../features/voicemailSlice";
import greetingReducer from "../features/greetingSlice"
import buttonReducer from "../features/buttonSlice"
import contactReducer from "../features/contactSlice"

export const store = configureStore({
  reducer: {
    mail: mailReducer,
    user: userReducer,
    call: callReducer,
    voicemail: voicemailReducer,
    greeting: greetingReducer,
    button: buttonReducer,
    contact: contactReducer,
  },
});
