import {IconButton} from "@material-ui/core";
import React from "react";
import "./ContactRow.css";
import * as libphonenumber from "libphonenumber-js"
import {sessionInfo} from "../../app/api"
import PersonIcon from "@material-ui/icons/Person";

function ContactRow({id, name, numbers}) {

    const title = () => {
        let t;
        if (numbers) {
            for (let i in numbers) {
                let number = numbers[i];
                try {
                    let n = libphonenumber.parse(number);
                    let formatted = libphonenumber.format(n, n.country === sessionInfo?.country ? 'NATIONAL' : 'INTERNATIONAL');
                    if (!formatted) {
                        formatted = number;
                    }
                    if (t) {
                        t += ", " + formatted;
                    } else {
                        t = formatted;
                    }
                } catch (e) {
                    if (t) {
                        t += ", " + number;
                    } else {
                        t = number;
                    }
                    console.log(e)
                }
            }
        }
        if (name) {
            return name + " (" + t + ")";
        }
        return t || "Unknown"
    }

    return (
        <div className="itemRow">
            <div className="itemRow-options">
                <IconButton>
                    <PersonIcon/>
                </IconButton>
            </div>
            <h3 className="contactRow-title">{title()}</h3>
        </div>
    );
}

export default ContactRow;
