import React from "react";
import "./Call.css";
import {Card, IconButton} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {getDevice,} from "../../features/callSlice";
import {useSelector, useDispatch} from "react-redux";
import {findContact, sessionInfo, broadcast, setCallAction} from "../../app/api";
import * as libphonenumber from "libphonenumber-js";
import {PhoneDisabled} from "@material-ui/icons";
import PhoneIcon from "@material-ui/icons/Phone";

import PersonIcon from "@material-ui/icons/Person";
import * as api from "../../app/api";

function IncomingCall({incomingCall, setIncomingCall}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const device = useSelector(getDevice);

    const title = () => {

        if (!incomingCall) {
            return "Unknown";
        }

        let name = findContact(incomingCall?.number)?.name

        let t = incomingCall?.number;
        if (t) {
            try {
                let n = libphonenumber.parse(incomingCall?.number)
                t = libphonenumber.format(n, n.country === sessionInfo?.country ? 'NATIONAL' : 'INTERNATIONAL')
            } catch (e) {
                console.log(e)
            }
        } else {
            t = "Unknown";
        }

        if (name) {
            return name + " (" + t + ")";
        }

        return t
    }
    const conferenceName = () => sessionInfo?.account_id?.hashCode() + "_" + incomingCall?.number?.hashCode() + "_conf";

    const answer = () => {
        console.log("incoming call answer")
        // check for active incoming call
        if (incomingCall) {
            if (!incomingCall.incoming) { // check for existing twilio connections
                // no active twilio call.
                // must be a call from a device
                let conf = conferenceName();
                console.log("incoming call setting conference action");
                let callSid = incomingCall.callSid;
                // STEP 1: set the call action
                // the action consists in moving the incoming call to conference line
                setCallAction({action: "conference", callSid: callSid, text: conf, number: incomingCall.number}, () => {
                    // check existing twilio call
                    if (!callSid) {
                        // no active twilio call, must be a call from a device
                        // lets ask the device to hangup the call. This is forward the call to Call Assistant
                        console.log("incoming call disconnect original call")
                        broadcast({'action': 'hangup'}, incomingCall.clientId, incomingCall.clientId);
                    }
                    // STEP 2: Connect to the conference call mentioned on Step 1
                    // these parameters will make the call connect the conference call
                    let params = {
                        conference: conf,
                        account: sessionInfo.account_id,
                    };
                    console.log("incoming call connect to conference", params);
                    device.connect(params);
                    setIncomingCall({...incomingCall, state: "connected"})
                });
            } else {
                console.log("incoming call accepting incoming call")
                incomingCall.incoming.accept();
                setIncomingCall({...incomingCall, state: "connected"})
            }
        }
    }

    /**
     * Hangup current call
     */
    const hangup = () => {
        // check twilio call
        if (incomingCall.callSid) {
            // send action to hangup call
            api.setCallAction({
                action: "hangup",
                callSid: incomingCall.callSid,
                text: "",
                voicemail: false,
                number: incomingCall.number,
                lang: incomingCall.language,
                voice: incomingCall.voice,
            }, () => {
            })
        } else if (incomingCall.incoming) {
            // reject incoming call
            incomingCall.incoming.reject();
            setIncomingCall(null);
        } else if (incomingCall.connection) {
            // disconnect all current calls
            device.disconnectAll();
        } else if (incomingCall.clientId) {
            // must be a call from another device
            // broadcast hangup message to device
            broadcast({'action': 'hangup'}, incomingCall.clientId, incomingCall.clientId);
        }
    }


    const callState = () => {
        let state = incomingCall?.state;
        if (state) {
            switch (state) {
                case "ringing":
                    if (incomingCall.clientId) {
                        return "Ringing on device";
                    } else {
                        return "Ringing";
                    }
                case "screening":
                    return "Screening call";
                case "connecting":
                    return "Connecting...";
                case "connected":
                    return "Connected";
                case "disconnected":
                    return "Disconnected";
                default:
                    return "Unknown..."
            }
        }
    }

    if (!incomingCall) {
        const path = window.location.pathname.split('/');
        if (path.length >= 5) {
            const type = path[2]; // video || call
            const number = path[3];
            const clientId = path[4];
            dispatch(() => {
                setIncomingCall({number: number, state: "ringing", clientId: clientId, video: type === "video"})
                history.push("/incoming");
            })
        }
        return (<></>)
    }

    return (
        <div className="incoming-call">
            <Card className="incoming-call-card">
                <br/>
                <div className="incoming-call-top">
                    <div className="incoming-call-top-wimage">
                        <PersonIcon/>
                    </div>
                    <div className="incoming-call-details" onClick={() => history.push("/incoming")}>
                        Incoming Call<br/>
                        <span className="incoming-call-title">{title()}</span>
                        <br/><span>{callState()}</span>
                    </div>
                </div>
                <br/>
                <div className="incoming-call-buttons">
                    {incomingCall && incomingCall.state !== "connected" &&
                        <span>
                            <IconButton onClick={() => answer()} className="incoming-call-button-answer">
                                <PhoneIcon/>
                            </IconButton>
                                &nbsp;Answer
                        </span>
                    }
                    <span className="incoming-call-button-hangup">
                        <IconButton onClick={() => hangup()}>
                            <PhoneDisabled/>
                        </IconButton>
                        &nbsp;Hangup
                    </span>
                </div>
            </Card>
        </div>
    );
}

String.prototype.hashCode = function () {
    let hash = 0, i, chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export default IncomingCall;
