import React, {useEffect, useRef, useState} from "react";
import {getButton, getButtons, setButton, setButtons} from "../../features/buttonSlice";
import {useHistory} from "react-router-dom";
import {FormControl, IconButton, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {TwitterPicker} from "react-color";
import * as utils from "../../app/utils";
import Button from "@mui/material/Button";
import {PlayArrow, SaveAlt} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {LazyLoadImage} from "react-lazy-load-image-component";
import * as api from "../../app/api";
import {showMessage} from "../../app/utils";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../Loading";
import SettingsIcon from "@material-ui/icons/Settings";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {generateGreetingAssistant} from "../../app/api";


const ButtonEditor = ({setPendingChanges, session}) => {
    const button = useSelector(getButton);
    const buttons = useSelector(getButtons);
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [showDelete, setShowDelete] = React.useState(false);
    const [showColor, setShowColor] = React.useState(false);
    const [showSelectIcon, setShowSelectIcon] = useState(false);
    const [icons, setIcons] = useState([]);
    const [iconsFilter, setIconsFilter] = useState(null);
    const [voices, setVoices] = useState(null);
    let audioRef = useRef();

    const updateSelectedButton = (btn) => {
        dispatch(setButton(btn));
        if (buttons) {
            dispatch(setButtons(buttons.map(button => button.pos === btn.pos ? btn : button)))
        }
    }

    const loadVoices = (btn) => {
        showMessage("Loading voices...");
        setVoices(null);
        setLoading(true);
        api.getVoicesByLang(btn.language || "en-US", function (result) {
            setLoading(false);
            setVoices(result.voices);
            console.log("voices loaded", result);
            if (button && button.language !== btn.language) {
                let b = {...btn};
                b.language = btn.language;
                b.voice = result[0].voice_id;
                updateSelectedButton(b);
                setPendingChanges(true);
            }
            setVoices(result);
        });
    }

    const handleButtonTitle = (event) => {
        let btn = {...button};
        btn.title = event.target.value;
        setPendingChanges(true);
        updateSelectedButton(btn);
    }

    const handleButtonText = (event) => {
        let btn = {...button};
        btn.text = event.target.value;
        setPendingChanges(true);
        updateSelectedButton(btn);
    }

    let filterTimeout;

    const handleIconFilter = (event) => {
        clearTimeout(filterTimeout);
        filterTimeout = setTimeout(() => {
            setIconsFilter(event.target.value);
        }, 500);
    }

    const buttonColorClicked = () => {
        setShowColor(!showColor)
    }

    const showDeleteButtonDlg = () => {
        setShowDelete(true);
    }
    const cancelDelete = () => {
        setShowDelete(false);
    }

    const deleteButton = () => {
        if (!button) {
            return;
        }
        let pos = 0;
        let newButtons = [...buttons].filter((b) => b.pos !== button.pos)
            .map((b) => { b.pos = pos++; return b; });
        dispatch(setButtons(newButtons));
        setShowDelete(false)
        utils.sleep(500).then(() => history.goBack());
        stopMessage()
    }

    const handleColorChange = (color) => {

        let btn = {...button};
        btn.color = color.hex.slice(-6);
        setShowColor(false);
        setPendingChanges(true);
        updateSelectedButton(btn);
    }

    const handleActionChange = (event) => {
        console.log("action changed " + event.target.value);
        let b = {...button};
        b.mode = Number(event.target.value);
        setPendingChanges(true);
        updateSelectedButton(b);
    };
    const handleVoiceChange = (event) => {
        console.log("voice changed " + event.target.value);
        let b = {...button};
        b.voice = event.target.value;
        setPendingChanges(true);
        updateSelectedButton(b);
        loadVoices(b);
        stopMessage()
    };
    const handleLanguageChange = (event) => {
        console.log("language changed " + event.target.value);
        let b = {...button};
        b.language = event.target.value
        updateSelectedButton(b);
        setPendingChanges(true);
        setTimeout(() => loadVoices(b), 500);
        stopMessage()
    };


    const saveButton = () => {
        setLoading(true)
        updateSelectedButton(button);
        setTimeout(() => {
            api.saveButtons(buttons).then(() => {
                setLoading(false);
                history.goBack();
            });
        }, 200)
    }
    const cancelSelectIcon = () => {
        setShowSelectIcon(false);
    }

    const iconClicked = () => {
        setShowSelectIcon(!showSelectIcon);
    }

    const messagePlaying = () => {
        setLoading(false)
    }

    const playMessage = (event) => {
        setLoading(true)
        let url = api.server + "/api/tts?text=" + encodeURIComponent(button.text) + "&voice_id=" + encodeURIComponent(button.voice);
        audioRef.current.setAttribute("src", url);
        audioRef.current.play();
    }
    const stopMessage = () => {
        setLoading(false)
        audioRef.current.pause();
    }


    const handleIconSelected = (id) => {
        let btn = {...button};
        btn.iconId = parseInt(id, 10);
        setShowSelectIcon(false);
        setPendingChanges(true);
        updateSelectedButton(btn);
    }

    function doGenerateGreeting() {
        setLoading(true);
        generateGreetingAssistant(button.language).then(result => {
            console.log(result);
            if (result.greeting) {
                let b = {...button};
                b.text = result.greeting
                if (setPendingChanges) setPendingChanges(true)
                dispatch(setButton(b));
            }
            setLoading(false)
        })
    }



    useEffect(() => {
        if (!button) {
            history.goBack();
        } else {
            loadVoices(button);
        }
    }, [])

    const IconsSelector = () => {

        return (<Dialog
            open={showSelectIcon}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle className="alert-dialog-icons-title">
                {"Select Icon"}
                <TextField
                    className="button-icon-search"
                    placeholder="Search icons"
                    onChange={handleIconFilter}
                    defaultValue={iconsFilter}
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-icons-content">
                    {icons.map(icon => {
                        if (iconsFilter) {
                            let f = iconsFilter.toLowerCase();
                            let found = false;
                            if (icon.tags && icon.tags.toLowerCase().indexOf(f) >= 0) {
                                found = true;
                            }
                            if (!found) {
                                return null;
                            }
                        }
                        return (<IconButton key={icon.id} onClick={() => handleIconSelected(icon.id)}>
                            <LazyLoadImage className="button-icon-list" alt={icon.tags}
                                           src={"/images/icons/svg/" + icon.id + ".svg"}/>
                        </IconButton>)
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelSelectIcon}>Cancel</Button>
            </DialogActions>
        </Dialog>)

    }

    return (<div className="buttons-editor">
        <div>
            <div className="page-settings">
                <div className="page-settingsLeft">
                    <Button onClick={() => history.goBack()}><ArrowBackIcon/></Button>
                    { buttons && <Button onClick={saveButton}><SaveAlt/>&nbsp;Save</Button>}
                </div>
                <div className="page-settingsRight">
                    <IconButton onClick={() => history.push("/settings")}>
                        <SettingsIcon/>
                    </IconButton>
                </div>
            </div>
            <h2 className="app-title">Assistant Button - {button?.title}</h2>

            <br/>
            <TextField
                className="button-title"
                placeholder="Insert title"
                label="Title"
                onBlur={handleButtonTitle}
                defaultValue={button?.title}
            />
            <div
                className="button-color"
                onClick={buttonColorClicked}
                style={{
                    backgroundColor: button?.color ? "#" + button?.color.slice(-6) : "#cccccc"
                }}>

            </div>
            <IconButton className="button-icon" onClick={iconClicked}>
                <img alt="Action Icon" src={"/images/icons/svg/" + button?.iconId + ".svg"}/>
            </IconButton>
            {showColor && <TwitterPicker
                color={button?.color ? "#" + button?.color.slice(-6) : "#cccccc"}
                onChangeComplete={handleColorChange}
            />}
        </div>
        <br/>
        <br/>
        <div className="greeting-text-container">
        <TextField
            className="button-text"
            id="greeting-text"
            placeholder="Type your message"
            label="Message"
            multiline
            minRows={4}
            onBlur={handleButtonText}
            value={button?.text}
        />
        <br/>
            <Button className="generate-greeting-assistant" onClick={() => doGenerateGreeting()}>Generate Greeting</Button>
        </div>
        <br/>
        <FormControl>
            <InputLabel id="languages-label">Languages</InputLabel>
            <Select
                labelId="languages-label"
                id="languages"
                defaultValue={button?.language || ""}
                autoWidth={true}
                onChange={handleLanguageChange}
            >
                {utils.languages.map((l) => <MenuItem key={l.id} value={l.id}
                                                      selected={l.id === button?.language}>{l.name}</MenuItem>)}
            </Select>
        </FormControl>
        <br/>
        <br/>
        {voices?.length > 0 && button && <FormControl>
            <InputLabel id="voices-label">Voices</InputLabel>
            <Select
                className="greetingText"
                labelId="voices-label"
                id="voices"
                onChange={handleVoiceChange}
                defaultValue={button?.voice || ""}
                autoWidth={true}
            >
                {voices.map((v) => <MenuItem key={v.voice_id} value={v.voice_id}
                                             selected={v.voice_id === button?.voice}>{v.voice}</MenuItem>)}
            </Select>
        </FormControl>}
        <br/>
        <br/>
        {button && <FormControl>
            <InputLabel id="action-label">Action</InputLabel>
            <Select
                className="greetingText"
                labelId="action-label"
                id="action"
                onChange={handleActionChange}
                defaultValue={button?.mode}
                autoWidth={true}>
                <MenuItem key="0" value="0" selected={0 === button?.mode}>Screen</MenuItem>
                <MenuItem key="1" value="1" selected={1 === button?.mode}>Hold</MenuItem>
                <MenuItem key="2" value="2" selected={2 === button?.mode}>Voicemail</MenuItem>
                {/*<MenuItem key="3" value="3" selected={3 === selectedButton.mode}>Hangup</MenuItem>*/}
            </Select>
        </FormControl>}
        <br/>
        <br/>
        <Button onClick={playMessage}><PlayArrow/>&nbsp;Play Message</Button>
        <Button onClick={showDeleteButtonDlg}><DeleteIcon/>&nbsp;Delete Button</Button>
        <audio ref={audioRef} onPlaying={messagePlaying} ></audio>
        {loading && <Loading/>}
        {icons && showSelectIcon && <IconsSelector/>}
        {showDelete &&  <Dialog
            open={showDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {"Delete"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you want to delete this button?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelDelete}>Cancel</Button>
                <Button onClick={deleteButton}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>}
    </div>);
}


export default ButtonEditor;
