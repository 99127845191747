import {Checkbox} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import "./CallList.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SettingsIcon from "@material-ui/icons/Settings";
import CallRow from "../CallRow/CallRow";

import * as api from "../../app/api";
import {cancelMessage, showMessage} from "../../app/utils";
import {useDispatch, useSelector} from "react-redux";
import {getCalls, getSelectedCalls, setCalls, setSelectedCalls} from "../../features/callSlice";
import {RefreshSharp} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";


function CallList({pageSize}) {

    const history = useHistory();
    const calls = useSelector(getCalls);
    const selectedCalls = useSelector(getSelectedCalls);
    const dispatch = useDispatch();
    const [showDeleteCalls, setShowDeleteCalls] = useState(false);

    let lastCallDate = calls?.length > 0 ? calls[calls.length - 1].date : new Date().getTime();
    let firstCallDate = calls?.length > 0 ? calls[0].date : new Date().getTime();

    const checkDates = () => {
        if (calls.length > 0) {
            lastCallDate = calls[calls.length - 1].date;
            firstCallDate = calls[0].date;
        }
    }

    const loadNextCalls = () => {
        checkDates();
        showMessage("Loading calls...")
        console.log("Loading next calls from " + new Date(lastCallDate))
        api.loadCallsBefore(pageSize, lastCallDate, function (response) {
            cancelMessage()
            if (response.calls?.length > 0) {
                dispatch(setCalls(response.calls));
                checkDates();
            } else {
                showMessage("No more calls")
            }
        })
    }

    const loadPreviousCalls = () => {
        checkDates();
        showMessage("Loading calls...")
        console.log("Loading previous calls from " + new Date(firstCallDate))
        api.loadCallsAfter(pageSize, firstCallDate, function (response) {
            cancelMessage()
            if (response.calls?.length > 0) {
                dispatch(setCalls(response.calls.sort((a, b) => b.date - a.date)));
                checkDates();
            } else {
                showMessage("No more calls")
            }
        })
    }

    const reload = () => {
        showMessage("Loading calls...")
        api.loadCallsBefore(pageSize, new Date().getTime(), function (response) {
            cancelMessage()
            if (response.calls?.length > 0) {
                dispatch(setCalls(response.calls));
                checkDates();
            } else {
                showMessage("No calls")
            }
        })
    }
    const deleteCalls = () => {
        setShowDeleteCalls(true);
    }

    const cancelDelete = () => {
        setShowDeleteCalls(false);
    }

    const doDelete = () => {
        setShowDeleteCalls(false);
        if (!selectedCalls || selectedCalls.length === 0) {
            console.log('no calls to delete')
            return;
        }

        let callIds = ""

        selectedCalls.forEach(call => {
            callIds += call + ","
        })

        api.deleteCall(callIds, () => {
            let callsNotDeleted = calls.filter(call => callIds.indexOf(call.id) < 0);
            dispatch(setCalls(callsNotDeleted));
            dispatch(setSelectedCalls([]));
        });

    }

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            let allCalls = calls.map(call => call.id);
            dispatch(setSelectedCalls(allCalls));
        } else {
            dispatch(setSelectedCalls([]));
        }
    }

    useEffect(() => reload(), [pageSize])

    return (
        <div className="callList">
            <div className="page-settings">
                <div className="page-settingsLeft">
                    <Button><Checkbox onChange={handleSelectAll}/> Select All</Button>
                    { selectedCalls && selectedCalls.length > 0 && <Button onClick={deleteCalls}>
                        <DeleteIcon/> Delete
                    </Button>}
                    <Button onClick={reload}>
                        <RefreshSharp/> Refresh
                    </Button>
                </div>
                <div className="page-settingsRight">
                    <Button onClick={() => loadPreviousCalls()}>
                        <ChevronLeftIcon/> Previous Page
                    </Button>
                    <Button onClick={() => loadNextCalls()}>
                        Next Page<ChevronRightIcon/>
                    </Button>
                    <Button onClick={() => history.push("/settings")}>
                        <SettingsIcon/> Settings
                    </Button>
                </div>
            </div>
            {/*<div className="callList-sections">*/}
            {/*    <Section Icon={InboxIcon} title="Primary" color="red" selected/>*/}
            {/*    <Section Icon={PeopleIcon} title="Social" color="#1A73E8"/>*/}
            {/*    <Section Icon={LocalOfferIcon} title="Promotions" color="green"/>*/}
            {/*</div>*/}


            <h2 className="app-title">Calls</h2>
            <div className="callList-list">
                {(calls == null || calls.length === 0) && <span className="callList-no-calls">No Calls</span>}
                {
                    calls.map((call) => (
                        <CallRow
                            key={call.id}
                            id={call.id}
                            sid={call.call_id}
                            number={call.number}
                            date={call.date}
                            duration={call.duration}
                            transcription={call.transcription}
                            video={call.video}
                            messages={call.messages}
                            recording={call.recording}
                            spam={call.spam}
                        />
                    ))}
            </div>
            {showDeleteCalls &&  <Dialog
                open={showDeleteCalls}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete these calls?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDelete}>Cancel</Button>
                    <Button onClick={doDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>}
        </div>
    );
}

export default CallList;
