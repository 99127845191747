import {IconButton} from "@material-ui/core";
import React from "react";
import "./NumberRow.css";
import * as libphonenumber from "libphonenumber-js"
import {sessionInfo} from "../../app/api"
import { SimCardOutlined} from "@material-ui/icons";

function NumberRow({number, date, callerId, voip}) {
    const title = () => {
        let t;
        try {
            let n = libphonenumber.parse(number);
            let formatted = libphonenumber.format(n, n.country === sessionInfo?.country ? 'NATIONAL' : 'INTERNATIONAL');
            if (formatted) {
                t = formatted;
            }
        } catch (e) {
            t = number;
            console.log(e)
        }
        return t || "Unknown"
    }
    return (
        <div className="itemRow">
            <div className="itemRow-options">
                <IconButton>
                    <SimCardOutlined/>
                </IconButton>
            </div>
            <div className="numberRow-title">
                <h3>{title()}</h3>
            </div>
            <div className="numberRow-message">
                <h4>
                </h4>
            </div>
        </div>
    );
}

export default NumberRow;
