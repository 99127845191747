import {Button, IconButton} from "@material-ui/core";
import React, {useRef, useState} from "react";
import "./GreetingRow.css";
import {CheckBox, CheckBoxOutlineBlank, GraphicEq, PlayArrow, Stop} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import {getInternationalNumber} from "../../app/utils";
import * as api from "../../app/api"
import {findContact} from "../../app/api";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Loading from "../../Loading";
function GreetingRow({greeting, onClick, reload}) {
    const [loading, setLoading] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    let audioRef = useRef();
    function playGreeting(event) {
        let url = getRecordingUrl()
        if (audioRef.current.paused !== false) {
            console.log( "playing " + url);
            setLoading(true)
            audioRef.current.setAttribute("src", getRecordingUrl());
            audioRef.current.play();
        } else {
            console.log( "pausing " + url);
            setLoading(false)
            audioRef.current.pause();
        }
        if (event) {
            event.stopPropagation();
        }
    }

    function getRecordingUrl() {
        if (greeting.type !== "url" || !greeting.url) {
            return api.server + "/api/tts?text=" + encodeURIComponent(greeting.text) + "&voice_id=" + encodeURIComponent( greeting.voice)
        }
        return greeting.url;
    }

    function onGreetingPlaying() {
        setLoading(false)
        setPlaying(true)
    }
    function onGreetingPaused() {
        setPlaying(false)
    }

    function isDefault() {
        return greeting.defaultGreeting === true
    }

    function title() {
        let t = "";
        if (greeting.numbers) {
            greeting.numbers.map((number) => {
                let c = findContact(number);
                if (c) {
                    if (t)  t = t + ", ";
                    t = t + c.name;
                } else  {
                    t = getInternationalNumber(number);
                }
            })
        } else {
            let c = findContact(greeting.number);
            if (c) {
                t = c.name + " (" + getInternationalNumber(greeting.number) + ")";
            }
            t = getInternationalNumber(greeting.number);
        }
        return t || "no number";
    }

    function setDefaultGreeting(event) {

        api.setDefaultGreeting(greeting.id).then(() => reload())

        if (event) {
            event.stopPropagation();
        }
    }

    function deleteGreeting(event) {
        api.deleteGreeting(greeting.id).then(() => reload())

        if (event) {
            event.stopPropagation();
        }

    }
    function confirmDeleteGreeting(event) {
        setShowDelete(true);
        if (event) {
            event.stopPropagation();
        }
    }

    function cancelDelete(event) {
        setShowDelete(false);
        if (event) {
            event.stopPropagation();
        }
    }

    return (
        <div className="itemRow fadeIn" onClick={onClick}>
            <div className="itemRow-options">
                <IconButton>
                    <GraphicEq/>
                </IconButton>
            </div>
            <div className="greetingRow-title">
                <b className="nowrap">{ title() }</b>
                <br/>
                <span className="greeting-item-text">{ greeting.type === "url" ? "[audio]" : greeting.text}</span>
            </div>
            <div>
                {(!isDefault()) && <Button className="greetingRow-btn" onClick={confirmDeleteGreeting}>
                    <DeleteIcon/><br/>Delete
                </Button>}
                {(!playing) && <Button className="greetingRow-btn">
                    <PlayArrow onClick={playGreeting}/><br/>Play
                </Button>}
                {playing && <Button className="greetingRow-btn">
                    <Stop onClick={playGreeting}/><br/> Stop
                </Button>}
                <Button className="greetingRow-btn">
                    {greeting.defaultGreeting && <CheckBox onClick={event => event.stopPropagation()}/>}
                    {!greeting.defaultGreeting && <CheckBoxOutlineBlank onClick={setDefaultGreeting}/>}
                    <br/> Default
                </Button>
            </div>
            <audio ref={audioRef} onPlaying={onGreetingPlaying} onPause={onGreetingPaused} ></audio>
            {loading && <Loading/>}
            {showDelete &&  <Dialog
                open={showDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete this greeting?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDelete}>Cancel</Button>
                    <Button onClick={deleteGreeting}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>}

        </div>
    );
}

export default GreetingRow;
